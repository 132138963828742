import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';

import { getNotifications } from 'redux/notifications/notifications.selectors';
import NotificationService from 'service/notification';

const DEFAULT_ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'center' };
const DEFAULT_HIDE_DURATION = 2000;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

let displayed = [];

export default function Notifications() {
  const notifications = useSelector(getNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    const getDefaultAction = (key) => {
      return (
        <IconButton
          aria-label='close'
          color='inherit'
          sx={{ p: 0.5 }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <BongoFontAwesomeIcon icon={faXmark} size={22} />
        </IconButton>
      );
    };

    notifications.forEach(
      ({
        key,
        message,
        action = getDefaultAction(key),
        anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
        autoHideDuration = DEFAULT_HIDE_DURATION,
        options = {},
        variant,
      }) => {
        if (displayed.includes(key)) return;

        let config = {
          key,
          action,
          anchorOrigin,
          autoHideDuration,
          options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            NotificationService.closeNotification(myKey);
            removeDisplayed(myKey);
          },
        };

        if (variant) {
          config = {
            ...config,
            content: (key, message) => (
              <Alert
                onClose={() => {
                  closeSnackbar(key);
                }}
                severity={variant}
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            ),
          };
        }

        enqueueSnackbar(message, config);
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar]);
}
