import store from 'redux/store';

import {
  addNotification as addNotificationAction,
  closeNotification as closeNotificationAction,
} from 'redux/notifications/notifications.actions';

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
};
class NotificationService {
  notify = (config) => {
    store.dispatch(addNotificationAction(config));
  };

  closeNotification = (key) => {
    store.dispatch(closeNotificationAction(key));
  };
}

export default new NotificationService();
