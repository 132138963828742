import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { ACTIVITY } from 'common/dattrs';
import ActivityService from 'service/activity';
import CloseableDialog from 'components/common/dialog/CloseableDialog';
import CourseService from 'service/course';
import LoadingButton from 'components/common/button/LoadingButton';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import { getRawCourses } from 'redux/course/course.selectors';
import { isAdmin } from 'redux/org/org.selectors';
import { ROLE_INSTRUCTOR } from 'common/constants/general';

import styles from './ActivityCopyDialog.module.scss';

const MAX_LENGTH = 200;

function ActivityCopyDialog(props) {
  const { activity, orgId, onCopyComplete, onHide } = props;
  const [activityName, setActivityName] = useState(t`Copy of ${activity?.name}`);
  const [activityNameValid, setActivityNameValid] = useState(true);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const _courses = useSelector(getRawCourses);
  const _isAdmin = useSelector(isAdmin);
  const nameRef = useRef('');

  const performActivityNameValidation = useCallback(() => {
    const isNameValid = !_.isEmpty(_.trim(activityName));
    if (!isNameValid) {
      setActivityNameValid(false);
      return;
    }

    setActivityNameValid(true);
  }, [activityName]);

  useEffect(() => {
    async function loadCourses() {
      setLoadingCourses(true);
      await CourseService.fetchCourses(orgId);
      setLoadingCourses(false);
    }

    if (orgId) {
      loadCourses();
    }
  }, [orgId]);

  useEffect(() => {
    const _availableCourses = [];
    for (let i = 0; i < _courses.length; i++) {
      if (_isAdmin || _courses[i].role === ROLE_INSTRUCTOR) {
        _availableCourses.push({ ..._courses[i], label: _courses[i].name });
      }
    }

    setCourses(_.orderBy(_availableCourses, ['name'], ['asc']));
    setSelectedCourse(_.find(_availableCourses, (_course) => _course.id === activity?.courseId));
  }, [_courses.length]); //eslint-disable-line

  useEffect(() => {
    performActivityNameValidation();
  }, [activityName, performActivityNameValidation]);

  useEffect(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.focus();
      nameRef.current.select();
    }
  }, [nameRef.current]); //eslint-disable-line

  async function onCopy() {
    setLoading(true);
    const result = await ActivityService.copyActivity({
      activeCourseId: activity.courseId,
      activityName,
      courseId: selectedCourse?.id,
      activityId: activity.id,
    });

    if (!result) {
      NotificationService.notify({
        key: 'activity-copy-error',
        message: t`Something went wrong trying to copy this assignment`,
        variant: NOTIFICATION_TYPES.ERROR,
      });
    } else {
      NotificationService.notify({
        key: 'activity-copy-success',
        message: t`Assignment copied`,
        variant: NOTIFICATION_TYPES.SUCCESS,
      });
      onCopyComplete(result);
    }
    setLoading(false);
  }

  return (
    <CloseableDialog
      {...ACTIVITY.copy.dialog}
      id='activity-copy-dialog'
      className={styles.dialog}
      contentClassName={styles.dialogContent}
      title={t`Copy Assignment`}
      description={t`Choose a course to copy this assignment to. The assignment configuration will be copied but not copy any learner content.`}
      fullScreen={false}
      fullWidth={true}
      onHide={(event) => {
        onHide(event);
      }}
      footerActions={
        <LoadingButton
          {...ACTIVITY.copy.submit}
          loading={loading}
          loadingMessage={t`Copying assignment`}
          variant='contained'
          disabled={loading || loadingCourses || !activityNameValid}
          onClick={onCopy}
        >
          {t`Copy Assignment`}
        </LoadingButton>
      }
    >
      <div className={styles.content}>
        <div className={styles.nameContainer}>
          <FormControl fullWidth>
            <TextField
              {...ACTIVITY.copy.activityName}
              id='copy-activity-name-field'
              inputRef={nameRef}
              disabled={loading}
              error={!activityNameValid}
              className={styles.nameInput}
              label={t`Assignment name`}
              helperText={!activityNameValid ? t`Assignment name can't be empty` : null}
              maxLength={MAX_LENGTH}
              value={activityName}
              onChange={(e) => {
                setActivityName(e.target.value);
                performActivityNameValidation();
              }}
            />
          </FormControl>
        </div>

        <div className={styles.courseContainer}>
          <FormControl fullWidth>
            <Autocomplete
              {...ACTIVITY.copy.courseName}
              id='copy-activity-course-selection'
              disabled={loading || loadingCourses}
              fullWidth
              noOptionsText={t`No courses found`}
              getOptionLabel={(course) => course.id === activity.courseId ? t`${course.name} (Current Course)` : course.name}
              openOnFocus={true}
              disableClearable={true}
              options={courses}
              value={selectedCourse}
              onChange={(event, courseItem) => {
                setSelectedCourse(courseItem);
              }}
              renderInput={(params) => <TextField {...params} label={t`Course`} />}
            />
          </FormControl>
        </div>
      </div>
    </CloseableDialog>
  );
}

ActivityCopyDialog.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    courseId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  orgId: PropTypes.string.isRequired,
  onCopyComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ActivityCopyDialog;
