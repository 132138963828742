import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import { getUserData, isSuperUser, isAuthenticating } from 'redux/user/user.selectors';
import Loader from 'components/common/loader/Loader';

function Home(props) {
  const _isAuthenticating = useSelector(isAuthenticating);
  const _isSuperUser = useSelector(isSuperUser);
  const userData = useSelector(getUserData);

  if (_isAuthenticating) {
    return <Loader />;
  }

  if (_.isEmpty(userData)) {
    return <Navigate to='/login' />;
  }

  const dest = window.sessionStorage.getItem('bongo-pre-login-destination') || '/';
  if (dest !== '/') {
    return <Navigate to={dest} />;
  }

  return _isSuperUser ? <Navigate to='/institutions' /> : <Navigate to='/courses' />;
}

export default Home;
