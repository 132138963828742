import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro';

import SortField from 'components/common/input/SortField';
import { getActivitySortField, getActivitySortDirection } from 'redux/activity/activity.selectors';
import { setActivitySortField, setActivitySortDirection } from 'redux/activity/activity.actions';
import { SORT_ASCENDING, SORT_DESCENDING } from 'common/constants/general';

const ActivityListSortField = (props) => {
  const sortField = useSelector(getActivitySortField);
  const sortDirection = useSelector(getActivitySortDirection);
  const dispatch = useDispatch();

  const items = [
    { label: t`Name`, value: 'name' },
    { label: t`Type`, value: 'type' },
  ];

  return (
    <SortField
      items={items}
      value={sortField}
      sortDirection={sortDirection}
      onSortDirectionToggle={() =>
        dispatch(setActivitySortDirection(sortDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING))
      }
      onSortFieldChange={(value) => dispatch(setActivitySortField(value))}
    />
  );
};

export default ActivityListSortField;
