import { ADD_SNACKBAR_NOTIFICATION, CLOSE_SNACKBAR_NOTIFICATION } from './notifications.types';

const INITIAL_STATE = {
  notifications: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SNACKBAR_NOTIFICATION: {
      const key = action.payload?.key;
      return {
        ...state,
        notifications: [...state.notifications, { ...action.payload, key: key || new Date().getTime() }],
      };
    }
    case CLOSE_SNACKBAR_NOTIFICATION:
      return { ...state, notifications: state.notifications.filter((notification) => notification.key !== action.key) };
    default:
      return state;
  }
};

export default reducer;
