import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIconButton = React.forwardRef((props, ref) => {
  const { children, disabled, forwardedRef, loading, variant, ...rest } = props;
  return (
    <IconButton ref={ref} disabled={loading || disabled} variant={variant} {...rest}>
      {loading ? <CircularProgress size={24} /> : children}
    </IconButton>
  );
});

LoadingIconButton.displayName = 'LoadingIconButton';

LoadingIconButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.any,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

LoadingIconButton.defaultProps = {
  disabled: false,
  loading: false,
  variant: 'outlined',
};

export default LoadingIconButton;
