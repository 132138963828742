import _ from 'lodash';
import { ORG_ADMIN } from 'common/constants/general';
import {
  ADD_ORG,
  ADD_ORG_MEMBER,
  SET_CURRENT_ORG,
  SET_ORGS,
  SET_ORG_MEMBERS,
  SET_ORGS_LOADING,
  // SET_DEFAULT_CURRENT_ORG,
  SET_ORG_USER_INFO,
  UPDATE_ORG_MEMBER_INFO,
} from './org.types';

const INITIAL_STATE = {
  org: {},
  orgs: [],
  orgMembers: [],
  orgAdmins: [],
  loading: true,
  orgUserInfo: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ORG:
      return { ...state, orgs: [...state.orgs, action.payload] };
    // case ADD_ORG_AND_SET_AS_CURRENT:
    //   return {
    //     ...state,
    //     orgs: [...state.orgs, { ...action.payload }],
    //     org: { ...action.payload },
    //   };
    case SET_ORGS:
      return { ...state, orgs: action.payload };
    case SET_CURRENT_ORG: {
      // const orgId = action.orgId || state.orgs[0].id;
      // if (!orgId) {
      //   return { ...state };
      // }

      // const org = _.find(state.orgs, (item) => item.id === orgId);
      // if (!org) {
      //   return { ...state };
      // }
      return { ...state, org: { ...action.payload } };
    }
    // case SET_DEFAULT_CURRENT_ORG: {
    //   const orgId = state.orgs[0]?.id;
    //   if (!orgId) {
    //     return { ...state };
    //   }
    //   const org = _.find(state.orgs, (item) => item.id === orgId);
    //   return { ...state, org: { ...org } };
    // }
    case ADD_ORG_MEMBER: {
      const members = [...state.orgMembers];
      members.push({...action.payload});
      const admins = _.filter(members, ['type', ORG_ADMIN]);
      return { ...state, orgMembers: members, orgAdmins: admins };
    }
    case SET_ORG_MEMBERS: {
      const members = [...action.payload];
      const admins = _.filter(members, ['type', ORG_ADMIN]);
      return { ...state, orgMembers: members, orgAdmins: admins };
    }
    case SET_ORGS_LOADING:
      return { ...state, loading: action.loading };
    case SET_ORG_USER_INFO: {
      return { ...state, orgUserInfo: { ...action.payload } };
    }
    case UPDATE_ORG_MEMBER_INFO: {
      const { userId } = action.payload;
      const members = [...state.orgMembers];
      const memberIndex = _.findIndex(members, { userId });
      if (memberIndex >= 0) {
        members[memberIndex] = { ...action.payload };
      }

      const admins = _.filter(members, ['type', ORG_ADMIN]);
      return { ...state, orgMembers: members, orgAdmins: admins };
    }
    default:
      return state;
  }
};

export default reducer;
