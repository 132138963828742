import store from 'redux/store';
import _ from 'lodash';

import { API, graphqlOperation } from 'aws-amplify';
import { getOrgUser, getOrgUsers, getOrgs, getOrg } from 'graphql/queries';
import { createOrg, addOrgMember as addOrgMemberMutation, updateOrgMember } from 'graphql/mutations';
import { ORG_ADMIN, ORG_USER } from 'common/constants/general';
import logger from 'common/utils/logger';

import {
  addOrg,
  addOrgMember,
  setOrgMembers,
  setCurrentOrg,
  setOrgs,
  setOrgsLoading,
  setOrgUserInfo,
  updateOrgMemberInfo,
} from 'redux/org/org.actions.js';
import { getOrgs as getOrgSelector } from 'redux/org/org.selectors';

const LOGGER_PREFIX = 'OrgService';

class OrgService {
  fetchOrgs = async () => {
    try {
      store.dispatch(setOrgsLoading(true));
      const orgSummary = await API.graphql(graphqlOperation(getOrgs));
      if (orgSummary == null) {
        logger.error(`${LOGGER_PREFIX}::fetchOrgs Failed to fetch orgSummary data for user - unknown cause `);
        return;
      }

      const orgs = _.get(orgSummary, 'data.getOrgs.orgs', []);
      const sorted = _.orderBy(orgs, ['name'], ['asc']);
      logger.debug(`${LOGGER_PREFIX}::fetchOrgs result`, sorted);

      store.dispatch(setOrgs(sorted));
      store.dispatch(setOrgsLoading(false));
      return orgs;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchOrgs`, err);
    }
  };

  fetchOrg = async (orgId) => {
    try {
      store.dispatch(setOrgsLoading(true));
      const org = await API.graphql(graphqlOperation(getOrg, { id: orgId }));
      // Everytime we load an org individualy, query to go
      // get any relevant org info about the current user as well.
      await this.fetchOrgUserInfo(orgId);

      const result = _.get(org, 'data.getOrg');
      logger.debug(`${LOGGER_PREFIX}::fetchOrg result`, result);
      store.dispatch(setCurrentOrg(result));
      store.dispatch(setOrgsLoading(false));
      return result;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchOrg`, err);
    }
  };

  fetchDefaultOrg = async () => {
    const orgs = await this.fetchOrgs();
    const defaultOrg = orgs[0];
    if (defaultOrg) {
      return await this.fetchOrg(defaultOrg.id);
    }

    return null;
  };

  fetchOrgUserInfo = async (orgId) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::fetchOrgUserInfo`);
      const result = await API.graphql(graphqlOperation(getOrgUser, { orgId }));
      logger.debug(`${LOGGER_PREFIX}::fetchOrgUserInfo result`, result);
      store.dispatch(setOrgUserInfo(_.get(result, 'data.getOrgUser', {})));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchOrgUserInfo`, err);
    }
  };

  setCurrentOrg = async (orgId) => {
    const orgs = getOrgSelector(store.getState());
    const org = _.find(orgs, { id: orgId });
    if (org) {
      store.dispatch(setCurrentOrg(org));
    }
  };

  createOrg = async (name) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::createOrg config`, { name });
      const result = await API.graphql(graphqlOperation(createOrg, { input: { name } }));
      logger.debug(`${LOGGER_PREFIX}::createOrg result`, result);
      store.dispatch(addOrg(_.get(result, 'data.createOrg', {})));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::createOrg`, err);
    }
  };

  loadOrgMembers = async (orgId) => {
    let result;
    try {
      logger.debug(`${LOGGER_PREFIX}::loadOrgMembers config`, { id: orgId });
      result = await API.graphql(graphqlOperation(getOrgUsers, { id: orgId }));
      logger.debug(`${LOGGER_PREFIX}::loadOrgMembers result`, result);
      store.dispatch(setOrgMembers(orgId, result.data.getOrgUsers.users));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::loadOrgMembers`, err);
    }

    return _.get(result, 'data.getOrgUsers.users', []);
  };

  addOrgMember = async (orgMember) => {
    const { orgId, userId, type } = orgMember;
    let result;
    try {
      logger.debug(`${LOGGER_PREFIX}::addOrgAdmin config`, { orgId, userId, type });
      result = await API.graphql(graphqlOperation(addOrgMemberMutation, { input: { orgId, userId, type } }));
      logger.debug(`${LOGGER_PREFIX}::addOrgAdmin result`, result);
      store.dispatch(addOrgMember(result.data.addOrgMember));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::addOrgAdmin`, err);
      throw err;
    }

    return _.get(result, 'data.addOrgMember');
  };

  addOrgAdmin = async (orgMember) => {
    const { orgId, userId } = orgMember;
    let result;
    try {
      logger.debug(`${LOGGER_PREFIX}::addOrgAdmin config`, { orgId, userId, type: ORG_ADMIN });
      result = await API.graphql(graphqlOperation(updateOrgMember, { input: { orgId, userId, type: ORG_ADMIN } }));
      logger.debug(`${LOGGER_PREFIX}::addOrgAdmin result`, result);
      store.dispatch(updateOrgMemberInfo(result.data.updateOrgMember));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::addOrgAdmin`, err);
      throw err;
    }

    return _.get(result, 'data.updateOrgMember');
  };

  removeOrgAdmin = async (orgMember) => {
    const { orgId, userId } = orgMember;
    let result;
    try {
      logger.debug(`${LOGGER_PREFIX}::removeOrgAdmin config`, { orgId, userId, type: ORG_USER });
      result = await API.graphql(graphqlOperation(updateOrgMember, { input: { orgId, userId, type: ORG_USER } }));
      logger.debug(`${LOGGER_PREFIX}::removeOrgAdmin result`, result);
      store.dispatch(updateOrgMemberInfo(result.data.updateOrgMember));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::removeOrgAdmin`, err);
      throw err;
    }

    return _.get(result, 'data.updateOrgMember');
  };
}

export default new OrgService();
