import React from 'react';
import PropTypes from 'prop-types';

function BongoLaunchForm(props) {
  const { url, inputs } = props;

  return (
    <html lang={navigator.language}>
      <head />
      <body>
        <form
          method="POST"
          action={url}
          name="ltiLaunchForm"
          id="ltiLaunchForm"
          encType="application/x-www-form-urlencoded"
        >
          {inputs.map(({ name, value }) => (
            <input type="hidden" key={name} name={name} value={value} />
          ))}
        </form>
        <script type="text/javascript">
          document.ltiLaunchForm.submit();
        </script>
      </body>
    </html>
  );
}

BongoLaunchForm.propTypes = {
  url: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default BongoLaunchForm;
