import {
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  SET_ACTIVITIES,
  SET_ACTIVITY_SORT_FIELD,
  SET_ACTIVITY_SORT_DIRECTION,
  SET_ACTIVITY_FILTER_FIELD_VALUE,
  UPDATE_ACTIVITY_FILTERS,
  CLEAR_ACTIVITY_FILTERS,
  DELETE_ACTIVITY,
} from './activity.types';

export function addActivity(payload) {
  return {
    type: ADD_ACTIVITY,
    payload,
  };
}

export function updateActivity(payload) {
  return {
    type: UPDATE_ACTIVITY,
    payload,
  };
}

export function setActivities(payload) {
  return {
    type: SET_ACTIVITIES,
    payload,
  };
}

export function setActivitySortField(value) {
  return {
    type: SET_ACTIVITY_SORT_FIELD,
    value,
  };
}

export function setActivitySortDirection(value) {
  return {
    type: SET_ACTIVITY_SORT_DIRECTION,
    value,
  };
}

export function setActivityFilterFieldValue(value) {
  return {
    type: SET_ACTIVITY_FILTER_FIELD_VALUE,
    value,
  };
}

export function updateActivityFilters(filters) {
  return {
    type: UPDATE_ACTIVITY_FILTERS,
    filters,
  };
}

export function clearActivityFilters() {
  return {
    type: CLEAR_ACTIVITY_FILTERS,
  };
}

export function deleteActivity(id) {
  return {
    type: DELETE_ACTIVITY,
    id,
  };
}
