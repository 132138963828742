import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';

import logger from 'common/utils/logger';
import store from 'redux/store';
import { updateUserInfo as updateUserInfoMutation, acceptTOC as acceptTOCMutation } from 'graphql/mutations';
import { setUserTOCResults } from 'redux/user/user.actions';

const LOGGER_PREFIX = 'UserService';

class UserService {
  // Notify the backend to update the users first name/last name from cognito.
  // This is use in-place of the getViewer query to get the users info on initial login
  updateUserInfo = async () => {
    try {
      logger.debug(`${LOGGER_PREFIX}::updateUserInfo`);
      const result = await API.graphql(graphqlOperation(updateUserInfoMutation));
      logger.debug(`${LOGGER_PREFIX}::updateUserInfo result`, result);
      return _.get(result, 'data.setUserInfo');
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::updateUserInfo`, err);
    }
  };

  acceptTOC = async () => {
    try {
      logger.debug(`${LOGGER_PREFIX}::acceptTOC`);
      const result = await API.graphql(graphqlOperation(acceptTOCMutation,  { input: { tocAccepted: true } }));
      logger.debug(`${LOGGER_PREFIX}::acceptTOC result`, result);
      store.dispatch(setUserTOCResults(_.get(result, 'data.setUserInfo'), {}));
      return result;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::acceptTOC`, err);
    }
  }
}

export default new UserService();
