/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by scripts/generate-amplify-config.js
import { Auth } from 'aws-amplify';

const awsmobile = {
  aws_appsync_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint: 'https://partner-dev.bongolearn.io/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_KQMjAsF68',
  aws_user_pools_web_client_id: '3bhkjtb3eoj562146pogstno1b',
  oauth: {
    domain: 'auth.partner-dev.bongolearn.io',
    scope: [ 'email', 'profile', 'openid' ],
    redirectSignIn: 'https://partner-dev.bongolearn.io/callback',
    redirectSignOut: 'https://partner-dev.bongolearn.io',
    responseType: 'token'
  },
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  })
};

export default awsmobile;
