import './common/styles/index.scss';
import './common/styles/vars.scss';

import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from 'aws-exports';

import useEventListener from 'common/utils/useEventListener';
import EnvironmentDialog from 'components/common/dialog/EnvironmentDialog';

import I18nLoader from './locale/I18nLoader';
import UserProvider from './context/UserProvider';
import Router from './Router';

// This allows us to have multiple sessions per browser window by using sessionStorage instead of localStorage(the default)
awsExports.Auth = {
  ...awsExports.Auth,
  storage: window.sessionStorage,
};

Amplify.configure(awsExports);

function App() {
  const [showEnvDialog, setShowEnvDialog] = useState(false);

  useEventListener('keydown', (event) => {
    if (event && event.altKey && event.code === 'Backquote') {
      setShowEnvDialog(true);
    }
  });

  return (
    <UserProvider>
      <I18nLoader>
        <Router />
        {showEnvDialog && (
          <EnvironmentDialog
            onHide={() => {
              setShowEnvDialog(false);
            }}
          />
        )}
      </I18nLoader>
    </UserProvider>
  );
}

export default App;
