import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import OrgService from 'service/org';
import { getCurrentOrg, getCurrentOrgAdmins } from 'redux/org/org.selectors';
import { ORG } from 'common/dattrs';

import AddAdminInput from './AddAdminInput';
import AdminItem from './AdminItem';
import styles from './InstitutionAdmin.module.scss';

function InstitutionAdmin(props) {
  const org = useSelector(getCurrentOrg);
  const admins = sortAdmins(useSelector(getCurrentOrgAdmins));
  const { id: orgId } = org;
  const adminCount = admins.length;

  function sortAdmins(members) {
    return _.orderBy(members, ['givenName', 'familyName', 'userId'], ['asc', 'asc', 'asc']);
  }

  const removeAdmin = React.useCallback(async (member) => {
    await OrgService.removeOrgAdmin(member);
  }, []);

  React.useEffect(() => {
    OrgService.loadOrgMembers(orgId);
  }, [orgId]);

  return (
    <div className={styles.container}>
      <h3>{t`Admins (${adminCount})`}</h3>
      <AddAdminInput orgId={orgId} />
      <div role='list' {...ORG.admin.list} className={styles.memberList}>
        {_.map(admins, (admin) => {
          return <AdminItem key={`org-admin[${admin.userId}]`} member={admin} removeAdmin={removeAdmin} />;
        })}
      </div>
    </div>
  );
}

export default InstitutionAdmin;
