import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Button from '@mui/material/Button';

import { ORG } from 'common/dattrs';
import styles from './EmptyInstitutionList.module.scss';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';

function EmptyInstitutionList(props) {
  const { onCreate } = props;
  return (
    <div {...ORG.emptyContainer} className={styles.container}>
      <div className={styles.graphic}>
        <div className={styles.itemContainer}>
          <div className={styles.item}>
            <BongoFontAwesomeIcon className={styles.icon} icon={faBuilding} size={18} />
            <div className={styles.itemContent}>
              <div className={styles.titleSection}></div>
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.item}>
            <BongoFontAwesomeIcon className={styles.icon} icon={faBuilding} size={18} />
            <div className={styles.itemContent}>
              <div className={styles.titleSection}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{t`Start by creating an institution`}</h3>
        <p className={styles.description}>{t`Once you have created an institution, you will be able to start creating courses.`}</p>
        <Button {...ORG.create} className={styles.createButton} variant='contained' onClick={onCreate}>{t`Create Institution`}</Button>
      </div>
    </div>
  );
}

EmptyInstitutionList.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default EmptyInstitutionList;
