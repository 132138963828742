import React from 'react';
import PropTypes from 'prop-types';

import { GENERAL } from 'common/dattrs';
import styles from './EmptyListMessage.module.scss';

function EmptyListMessage(props) {
  const { action, title, description } = props;

  return (
    <div {...GENERAL.emptyList} className={styles.container}>
      <div className={styles.graphicContainer}>
        <div className={styles.graphic}>
          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <div className={styles.titleSection}></div>
              <div className={styles.descriptionSection}></div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.item}>
              <div className={styles.titleSection}></div>
              <div className={styles.descriptionSection}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
          {action && <div className={styles.actions}>{action}</div>}
        </div>
      </div>
    </div>
  );
}

EmptyListMessage.propTypes = {
  action: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string,
};

EmptyListMessage.defaultProps = {
  description: '',
  title: '',
};

export default EmptyListMessage;
