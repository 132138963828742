import _ from 'lodash';

export function isAutoEnrollmentActive(autoEnrollmentConfig) {
  const autoEnrollmentEnabled = autoEnrollmentConfig && autoEnrollmentConfig.enabled;
  if (!autoEnrollmentEnabled) {
    return false;
  }

  const startDate = _.get(autoEnrollmentConfig, 'start');
  const endDate = _.get(autoEnrollmentConfig, 'end');
  const now = new Date().getTime();

  if (startDate && endDate) {
    return now >= startDate && now <= endDate;
  } else if (startDate) {
    return now >= startDate;
  } else if (endDate) {
    return now <= endDate;
  }

  return true;
}
