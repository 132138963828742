import React, { useLayoutEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import Button from '@mui/material/Button';

import ActivityListOverview from 'components/common/ActivityListOverview';
import CourseList from 'components/course/CourseList';
import InstitutionActions from 'components/institution/InstitutionActions';
import CourseAddDialog from 'components/course/CourseAddDialog';
import CourseService from 'service/course';
import EmptyListMessage from 'components/EmptyListMessage';
import InstitutionBreadcrumb from 'components/institution/InstitutionBreadcrumb';
import OrgService from 'service/org';
import Link from 'components/Link';
import Loader from 'components/common/loader/Loader';
import MissingInstitution from 'components/institution/MissingInstitution';
import Permissions from 'common/utils/permissions';

import { COURSE } from 'common/dattrs';
import { getCourses, getRawCoursesCount } from 'redux/course/course.selectors';
import { getCurrentOrg, getOrgs } from 'redux/org/org.selectors';
import { isSuperUser } from 'redux/user/user.selectors';

import Page from './Page';
import styles from './Institution.module.scss';

function Institution() {
  const { institutionId } = useParams();
  const org = useSelector(getCurrentOrg);
  const orgs = useSelector(getOrgs);
  const courses = useSelector(getCourses);
  const _isSuperUser = useSelector(isSuperUser);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingOrg, setLoadingOrg] = useState(true);

  const rawCoursesCount = useSelector(getRawCoursesCount);
  const isCourseCreateVisible = Permissions.isCourseCreateVisible(courses);

  async function fetchOrg(id) {
    setLoadingOrg(true);
    await OrgService.fetchOrg(id);
    setLoadingOrg(false);
  }

  async function fetchDefaultOrg() {
    setLoadingOrg(true);
    const result = await OrgService.fetchDefaultOrg();
    setLoadingOrg(false);
    return result;
  }

  async function fetchCourses(id) {
    setLoadingCourses(true);
    await CourseService.fetchCourses(id);
    setLoadingCourses(false);
  }

  /* Using two effect to load the data. institutionId should only be set
     when the user is a super admin. That will be part of the URL then.
     The other path is for typical users where they have a "default" org
     that is not part of the URL. To avoid double loading, I check to make sure
     institutionId is not set in the first one. Fetching an org will update the org.id
  */
  useLayoutEffect(() => {
    if (!institutionId) {
      if (org?.id) {
        fetchOrg(org.id);
        fetchCourses(org.id);
      } else {
        fetchDefaultOrg().then((result) => {
          if (result) {
            fetchCourses(result.id);
          } else {
            setLoadingCourses(false);
          }
        });
      }
      setLoadingOrg(false);
    }
  }, [org?.id]); //eslint-disable-line

  useLayoutEffect(() => {
    if (institutionId) {
      fetchOrg(institutionId);
      fetchCourses(institutionId);
    }

    setLoadingOrg(false);
  }, [institutionId]); //eslint-disable-line

  async function onCreateCourse(name) {
    try {
      setCreateDialogVisible(false);
      setLoaderVisible(true);
      await CourseService.createCourse(name, org.id);
      setLoaderVisible(false);
    } catch (err) {
      console.error(`Error creating course '${name}':`, err);
    }
  }

  function courseListEmpty() {
    return !loadingCourses && rawCoursesCount === 0;
  }

  function getCourseComponent() {
    if (loadingOrg) {
      return null;
    }

    if (_.isEmpty(orgs)) {
      return <MissingInstitution />;
    }

    if (courseListEmpty()) {
      if (isCourseCreateVisible) {
        return (
          <>
            <EmptyListMessage
              title={t`Start by creating a course`}
              description={t`You don't currently have any courses. Once you have a course, you can enroll instructors and learners in the course, and you can create assignments for those learners to take.`}
              action={
                <Button
                  {...COURSE.create}
                  className={styles.createButton}
                  variant='contained'
                  onClick={() => {
                    setCreateDialogVisible(true);
                  }}
                >{t`Create Course`}</Button>
              }
            />
            <ActivityListOverview />
          </>
        );
      }

      return (
        <>
          <EmptyListMessage
            title={t`Couldn't find any courses`}
            description={t`Looks like you are not enrolled in any courses yet. Once you have been added to a course, you will be able to come back here and see it.`}
          />
          <ActivityListOverview />
        </>
      );
    }

    return <CourseList courses={courses} />;
  }

  function getSecondaryTitle() {
    if (rawCoursesCount === 0) {
      return <span />;
    }

    const filteredCoursesCount = courses.length;
    const totalCoursesCount = rawCoursesCount;
    if (filteredCoursesCount !== totalCoursesCount) {
      return (
        <div>
          <span {...COURSE.count}>{t`(showing ${filteredCoursesCount} of ${totalCoursesCount})`}</span>
        </div>
      );
    }

    return <span {...COURSE.count}>({totalCoursesCount})</span>;
  }

  function getBreadcrumbs() {
    if (!org || _.isEmpty(orgs)) {
      return [];
    }

    const crumbs = [];
    if (_isSuperUser) {
      crumbs.push(
        <Link key={'institutions'} to={'/institutions'}>
          {t`Institutions`}
        </Link>
      );
    }

    crumbs.push(<InstitutionBreadcrumb key={'institution'} />);
    return crumbs;
  }

  function getActions() {
    return <InstitutionActions />;
  }

  return (
    <Page
      title={t`Courses`}
      secondaryTitle={getSecondaryTitle()}
      actions={getActions()}
      actionsClassName={styles.actions}
      breadcrumbs={getBreadcrumbs()}
    >
      <Loader visible={loaderVisible || loadingCourses || loadingOrg} />
      {getCourseComponent()}
      {createDialogVisible && (
        <CourseAddDialog onCancel={() => setCreateDialogVisible(false)} onCreate={onCreateCourse} />
      )}
    </Page>
  );
}

export default Institution;
