import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

import App from './App';
import Notifications from 'components/notifications/Notifications';
import * as serviceWorker from './serviceWorker';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2679c7',
    },
    secondary: {
      main: '#C9281D',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: 'black',
        },
        tooltip: {
          fontSize: '0.82rem',
          backgroundColor: 'black',
        },
      },
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <App />
          <Notifications />
        </SnackbarProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
