import store from 'redux/store';

import { isAdmin, isOrgAdmin } from 'redux/org/org.selectors';

import { ROLE_INSTRUCTOR, ROLE_REVIEWER } from 'common/constants/general';

class Permissions {
  _isInstructionOrAdmin(role) {
    return isAdmin(store.getState()) || role === ROLE_INSTRUCTOR;
  }

  isCourseCreateVisible(courses) {
    if (isAdmin(store.getState())) {
      return true;
    }

    let visible = false;
    if (courses) {
      for (let i = 0; i < courses.length; i++) {
        if ([ROLE_INSTRUCTOR].includes(courses[i].role)) {
          visible = true;
          break;
        }
      }
    }

    return visible;
  }

  isCourseCopyVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isCourseCopyLinkVisible(role) {
    return isAdmin(store.getState()) || [ROLE_INSTRUCTOR, ROLE_REVIEWER].includes(role);
  }

  isCourseDownloadSCORMVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isCourseSettingsVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isCourseAutoEnrollmentStatusVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isCourseAutoEnrollmentStatusVisibleForAnyCourse(courses) {
    if (isAdmin(store.getState())) {
      return true;
    }

    let visible = false;
    for (let i = 0; i < courses.length; i++) {
      if (this.isCourseAutoEnrollmentStatusVisible(courses[i].role)) {
        visible = true;
        break;
      }
    }

    return visible;
  }

  isCourseRoleFilterVisible(courses) {
    if (isAdmin(store.getState())) {
      return false;
    }

    let visible = false;
    for (let i = 0; i < courses.length; i++) {
      if ([ROLE_INSTRUCTOR, ROLE_REVIEWER].includes(courses[i].role)) {
        visible = true;
        break;
      }
    }

    return visible;
  }

  isCourseMembershipVisible(role) {
    return isAdmin(store.getState()) || [ROLE_INSTRUCTOR, ROLE_REVIEWER].includes(role);
  }

  isActivityCreationVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivityCopyVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivityCopyLinkVisible(role) {
    return isAdmin(store.getState()) || [ROLE_INSTRUCTOR, ROLE_REVIEWER].includes(role);
  }

  isActivityVisibilityVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivityDownloadSCORMVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivityDeleteVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivitySettingsVisible(role) {
    return this._isInstructionOrAdmin(role);
  }

  isActivityLaunchAsLearnerVisible() {
    return isOrgAdmin(store.getState());
  }

  isInstitutionUserManagementVisible() {
    return isAdmin(store.getState());
  }

  isInstitutionSettingsVisible() {
    return isAdmin(store.getState());
  }
}

export default new Permissions();
