import { SET_USER_DATA, SET_AUTHENTICATING, SET_TOC_RESULTS } from './user.types';

const INITIAL_STATE = {
  userData: {},
  authenticating: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userData: action.payload };
    case SET_AUTHENTICATING:
      return { ...state, authenticating: action.authenticating };
    case SET_TOC_RESULTS:
      return { ...state, userData: { ...state.userData, viewer: { ...state.userData.viewer, ...action.payload } } };
    default:
      return state;
  }
};

export default reducer;
