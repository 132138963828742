import { SET_VIEWPORT_DIMENSIONS, SET_VIEWPORT_ORIENTATION, SET_VIEWPORT_SIZE } from './browser.types';

const INITIAL_STATE = {
  viewport: {
    size: null,
    orientation: null,
    dimensions: {
      width: null,
      height: null,
    },
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VIEWPORT_DIMENSIONS: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          dimensions: action.payload,
        },
      };
    }
    case SET_VIEWPORT_ORIENTATION: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          orientation: action.payload,
        },
      };
    }
    case SET_VIEWPORT_SIZE: {
      return {
        ...state,
        viewport: {
          ...state.viewport,
          size: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
