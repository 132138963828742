export const getCourses = (state) => state.courses?.courses;
export const getActiveCourseMembers = id => state => state.courses?.activeCourseMembers[id];
export const getRawCourses = (state) => state.courses?.rawCourses;
export const getRawCoursesCount = (state) => state.courses?.rawCourses.length;
export const getActiveCourse = (state) => state.courses?.activeCourse;
export const getCourseSortField = (state) => state.courses?.sortColumn;
export const getCourseSortDirection = (state) => state.courses?.sortDirection;
export const getCourseFilterFieldValue = (state) => state.courses?.filterValue;
export const getCourseFilters = (state) => state.courses?.filters;
export const getCoursesInCopyProgress = (state) => state.courses?.coursesInCopyProgress;
export const getCoursesCopySuccess = (state) => state.courses?.coursesCopySuccess;
export const getCourseCopyInitiatedFlag = (state) => state.courses?.userInitiatedCourseCopy;