import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import ActivityService from 'service/activity';
import CloseableDialog from 'components/common/dialog/CloseableDialog';
import CourseService from 'service/course';
import LoadingButton from 'components/common/button/LoadingButton';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import { getActivities } from 'redux/activity/activity.selectors';

import styles from './CourseCopyDialog.module.scss';

const MAX_LENGTH = 200;

function CourseCopyDialog(props) {
  const { course, onHide } = props;
  const [courseName, setCourseName] = useState(t`Copy of ${course.name}`);
  const [courseNameValid, setCourseNameValid] = useState(true);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [persisting, setPersisting] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const activities = useSelector(getActivities);

  const performCourseNameValidation = useCallback(() => {
    const isNameValid = !_.isEmpty(_.trim(courseName));
    if (!isNameValid) {
      setCourseNameValid(false);
      return;
    }

    setCourseNameValid(true);
  }, [courseName]);

  useEffect(() => {
    performCourseNameValidation();
  }, [courseName, course, performCourseNameValidation]);

  useEffect(() => {
    fetchData();
  }, []); //eslint-disable-line

  useEffect(() => {
    setSelectedActivities(activities.map((activity) => activity.id));
  }, [activities]); //eslint-disable-line

  async function fetchData() {
    setLoadingActivities(true);
    if (course.id) {
      await ActivityService.fetchActivities(course.id);
    }
    setLoadingActivities(false);
  }

  async function onCopy() {
    setPersisting(true);
    try {
      await CourseService.copyCourse({
        courseId: course.id,
        courseName,
        activityIds: selectedActivities,
      });
      onHide();
    } catch (e) {
      NotificationService.notify({
        key: 'copy-course-error',
        message: t`Something went wrong trying to copy this course`,
        variant: NOTIFICATION_TYPES.ERROR,
      });
    }

    setPersisting(false);
  }

  function toggleActivitySelection(activityId) {
    if (selectedActivities.includes(activityId)) {
      const _activities = [...selectedActivities];
      _.remove(_activities, (id) => {
        return id === activityId;
      });
      setSelectedActivities(_activities);
    } else {
      setSelectedActivities([...selectedActivities, activityId]);
    }
  }

  return (
    <CloseableDialog
      id='course-copy-dialog'
      description={t`Choose which assignments should be copied with this course. Members of this course will not be copied to the new course.`}
      className={styles.dialog}
      contentClassName={styles.dialogContent}
      title={t`Copy Course`}
      fullScreen={false}
      fullWidth={true}
      onHide={(event) => {
        onHide(event);
      }}
      footerActions={
        <LoadingButton
          variant='contained'
          loading={persisting}
          disabled={persisting || loadingActivities}
          onClick={onCopy}
        >
          {t`Copy Course`}
        </LoadingButton>
      }
    >
      <div className={styles.content}>
        <div className={styles.nameContent}>
          <FormControl className={styles.nameForm} fullWidth>
            <TextField
              id='course-name-field'
              name='course-name'
              disabled={persisting}
              error={!courseNameValid}
              className={styles.nameInput}
              label={t`Course name`}
              helperText={!courseNameValid ? t`Course name can't be empty` : null}
              maxLength={MAX_LENGTH}
              value={courseName}
              onChange={(e) => {
                setCourseName(e.target.value);
                performCourseNameValidation();
              }}
            />
          </FormControl>
        </div>
        {loadingActivities && (
          <div className={styles.activityLoader}>
            <CircularProgress size={32} />
          </div>
        )}
        {!loadingActivities && !_.isEmpty(activities) && (
          <div className={styles.activityContent}>
            <div className={styles.infoContainer}>
              <p>{t`Assignments:`}</p>
            </div>
            <List className={styles.activityList}>
              {activities.map((activity) => {
                const labelId = `activity-list-label-${activity.id}`;

                return (
                  <ListItem key={activity.id} disablePadding>
                    <ListItemButton
                      role={undefined}
                      disabled={persisting}
                      dense
                      onClick={() => {
                        toggleActivitySelection(activity.id);
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          disabled={persisting}
                          checked={selectedActivities.includes(activity.id)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={activity.name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </div>
    </CloseableDialog>
  );
}

CourseCopyDialog.propTypes = {
  course: PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }),
  onHide: PropTypes.func.isRequired,
};

export default CourseCopyDialog;
