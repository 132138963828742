import { SET_USER_DATA, SET_AUTHENTICATING, SET_TOC_RESULTS } from './user.types';

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload,
  };
}

export function setAuthenticating(authenticating) {
  return {
    type: SET_AUTHENTICATING,
    authenticating,
  };
}

export function setUserTOCResults(payload) {
  return {
    type: SET_TOC_RESULTS,
    payload,
  };
}
