import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Section.module.scss';

function Section(props) {
  const { children, className, headerActions, headerClassName, secondaryTitle, title } = props;
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.header, headerClassName)}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          {secondaryTitle && <span className={styles.secondaryTitle}>{secondaryTitle}</span>}
        </div>
        <div className={styles.headerActions}>{headerActions}</div>
      </div>
      <div className={styles.contents}>{children}</div>
    </div>
  );
}

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  className: PropTypes.string,
  headerActions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  headerClassName: PropTypes.string,
  secondaryTitle: PropTypes.string,
  title: PropTypes.string,
};

Section.defaultProps = {
  secondaryTitle: null,
  title: '',
};

export default Section;
