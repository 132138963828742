import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from 'components/common/tooltip/Tooltip';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import MemberRoleSelect from 'components/course/settings/MemberRoleSelect';
import { COURSE } from 'common/dattrs';
import CourseService from 'service/course';
import logger from 'common/utils/logger';
import styles from './AddMemberInput.module.scss';

export default function AddMemberInput(props) {
  const { courseId } = props;
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('learner');
  const [emailValid, setEmailValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(' ');
  const [addingMember, setAddingMember] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);

  const isEmailValid = emailValid && !_.isEmpty(_.trim(userEmail));
  const roleRef = useRef('');
  const MAX_LENGTH = 200;
  const ACTION_ICON_SIZE = 24;

  useEffect(() => {
    if (addingMember) {
      roleRef.current.focus();
    }
  }, [addingMember]);

  async function onAddMember() {
    setIsPersisting(true);
    const targetMember = { courseId, userId: _.trim(userEmail), role: userRole };

    try {
      await CourseService.addCourseMember(targetMember);
    } catch (e) {
      logger.error('Could not add course member', targetMember);
    }

    setUserEmail('');
    setIsPersisting(false);
    setAddingMember(false);
  }

  function onEmailChange(event) {
    const email = event.target.value;
    setUserEmail(email);

    if (!_.isEmpty(email)) {
      // eslint-disable-next-line no-useless-escape
      const valid = /[^\s]+@[^\s]+\.[^\s]+/.test(email);
      setEmailValid(valid);
      setEmailErrorMessage(valid ? ' ' : t`Enter a valid email address`);
    } else {
      setEmailValid(true);
      setEmailErrorMessage(' ');
    }
  }

  function getAddInputs() {
    if (!addingMember) {
      return null;
    }

    return (
      <Paper elevation={0} className={styles.addPaper}>
        <div className={styles.addContainer}>
          <div className={styles.emailContainer}>
            <FormControl fullWidth>
              <TextField
                id='course-members-entry-field'
                {...COURSE.members.add.email}
                error={!emailValid}
                className={styles.emailInput}
                name='user-email'
                disabled={isPersisting}
                // eslint-disable-next-line no-useless-escape
                inputProps={{ pattern: '[^s]+@[^s]+.[^s]+' }}
                inputRef={roleRef}
                label={t`User email`}
                maxLength={MAX_LENGTH}
                value={userEmail}
                onChange={onEmailChange}
              />
            </FormControl>
          </div>
          <div className={styles.roleContainer}>
            <MemberRoleSelect
              {...COURSE.members.add.role}
              fullWidth
              disabled={isPersisting}
              selectedRole={userRole}
              onChange={setUserRole}
            />

            <div className={styles.actionsContainer}>
              {isPersisting ? (
                <Box className={styles.progressContainer}>
                  <CircularProgress size={ACTION_ICON_SIZE} />
                </Box>
              ) : (
                <Tooltip title={t`Add member`}>
                  <span>
                    <IconButton
                      {...COURSE.members.add.submit}
                      aria-label={t`Add member`}
                      disabled={!isEmailValid}
                      className={styles.addEmailButton}
                      onClick={onAddMember}
                    >
                      <BongoFontAwesomeIcon icon={faPlus} size={ACTION_ICON_SIZE} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip title={t`Cancel`}>
                <span>
                  <IconButton
                    {...COURSE.members.add.cancel}
                    aria-label={t`Cancel`}
                    className={styles.addEmailButton}
                    disabled={isPersisting}
                    onClick={() => {
                      setAddingMember(false);
                    }}
                  >
                    <BongoFontAwesomeIcon icon={faXmark} size={ACTION_ICON_SIZE} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div aria-live='polite' {...COURSE.members.add.errorMessage} className={styles.addError}>
          {emailErrorMessage}
        </div>
      </Paper>
    );
  }

  return (
    <div className={styles.container}>
      <ListItemButton
        {...COURSE.members.addMember}
        component='a'
        onClick={() => {
          setAddingMember(true);
        }}
        className={cx(styles.addMemberButton, addingMember && styles.hidden)}
      >
        <BongoFontAwesomeIcon className={styles.addIcon} icon={faUserPlus} size={28} />
        <span className={styles.name}>{t`Add Member`}</span>
      </ListItemButton>

      {getAddInputs()}
    </div>
  );
}

AddMemberInput.propTypes = {
  courseId: PropTypes.string.isRequired,
};
