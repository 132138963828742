import {
  ADD_ORG,
  ADD_ORG_MEMBER,
  SET_CURRENT_ORG,
  SET_ORGS,
  SET_ORG_MEMBERS,
  SET_ORGS_LOADING,
  SET_ORG_USER_INFO,
  UPDATE_ORG_MEMBER_INFO,
} from './org.types';

export function setOrgs(payload) {
  return {
    type: SET_ORGS,
    payload,
  };
}

export function setCurrentOrg(payload) {
  return {
    type: SET_CURRENT_ORG,
    payload,
  };
}

export function setOrgMembers(id, payload) {
  return {
    id,
    type: SET_ORG_MEMBERS,
    payload,
  };
}

export function setOrgsLoading(loading) {
  return {
    type: SET_ORGS_LOADING,
    loading,
  };
}

export function addOrg(payload) {
  return {
    type: ADD_ORG,
    payload,
  };
}

export function setOrgUserInfo(payload) {
  return {
    type: SET_ORG_USER_INFO,
    payload,
  };
}

export function updateOrgMemberInfo(payload) {
  return {
    type: UPDATE_ORG_MEMBER_INFO,
    payload,
  };
}

export function addOrgMember(payload) {
  return {
    type: ADD_ORG_MEMBER,
    payload,
  };
}