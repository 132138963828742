import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import CourseListFilterField from './CourseListFilterField';
import CourseListFilterInput from './CourseListFilterInput';
import CourseListSortField from './CourseListSortField';
import styles from './CourseListOptions.module.scss';

const CourseListOptions = (props) => {
  const mediumWidth = useMediaQuery('(min-width:700px)');

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <CourseListFilterField />
        <CourseListSortField />
      </div>

      <div className={styles.filterInputContainer}>
        {mediumWidth && <CourseListFilterInput className={styles.filterInput} />}
      </div>
    </div>
  );
};

export default CourseListOptions;
