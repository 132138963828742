import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Slide from '@mui/material/Slide';

import { GENERAL } from 'common/dattrs';
import styles from './InlineConfirm.module.scss';

const ENTER_TIMEOUT = 500;

function InlineConfirm(props) {
  const { cancelAriaLabel, confirmAriaLabel, confirmMessage, onConfirm, onCancel, visible } = props;
  const confirmRef = useRef('');

  // useEffect(() => {
  //   if (visible && confirmRef && confirmRef.current) {
  //     setTimeout(() => {
  //       confirmRef.current.focus();
  //     }, ENTER_TIMEOUT);
  //   }
  // }, [visible]); //eslint-disable-line

  if (!visible) {
    return false;
  }

  return (
    <ClickAwayListener onClickAway={onCancel}>
      <Slide
        aria-live='polite'
        className={styles.confirmOverlayContainer}
        direction='left'
        in={visible}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: ENTER_TIMEOUT, exit: 0 }}
      >
        <div className={styles.confirmOverlay}>
          <span className={styles.confirmMessage}>{confirmMessage}</span>
          <Button
            {...GENERAL.confirm.accept}
            aria-label={confirmAriaLabel}
            ref={confirmRef}
            color='primary'
            className={styles.confirmButton}
            variant='outlined'
            onClick={onConfirm}
          >
            {t`Yes`}
          </Button>
          <Button
            {...GENERAL.confirm.decline}
            aria-label={cancelAriaLabel}
            variant='outlined'
            color='secondary'
            onClick={onCancel}
          >
            {t`No`}
          </Button>
        </div>
      </Slide>
    </ClickAwayListener>
  );
}

InlineConfirm.propTypes = {
  cancelAriaLabel: PropTypes.string,
  confirmAriaLabel: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InlineConfirm.defaultProps = {
  visible: false,
};

export default InlineConfirm;
