import React from 'react';

import CourseCopyNotification from './CourseCopyNotification';
import styles from './AppBarNotifications.module.scss';

export default function AppBarNotifications() {
  return (
    <div className={styles.container}>
      <CourseCopyNotification />
    </div>
  );
}
