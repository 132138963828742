import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Tooltip from 'components/common/tooltip/Tooltip';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import Avatar from 'components/common/avatar/Avatar';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import { ORG } from 'common/dattrs';
import { ORG_ADMIN } from 'common/constants/general';
import OrgService from 'service/org';
import { getCurrentOrgMembers } from 'redux/org/org.selectors';
import logger from 'common/utils/logger';

import styles from './AddAdminInput.module.scss';

export default function AddAdminInput(props) {
  const { orgId } = props;
  const [userEmail, setUserEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(' ');
  const [addingMember, setAddingMember] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);
  const orgMembers = useSelector(getCurrentOrgMembers);
  const [selectedUser, setSelectedUser] = useState({});

  const isEmailValid = emailValid && !_.isEmpty(_.trim(userEmail));
  const roleRef = React.useRef('');
  const MAX_LENGTH = 200;
  const ACTION_ICON_SIZE = 24;
  const orgMembersPartition = _.partition(orgMembers, (members) => {
    return members.type === ORG_ADMIN;
  });

  useEffect(() => {
    if (addingMember) {
      roleRef.current.focus();
    }
  }, [addingMember]);

  async function onAddMember() {
    setIsPersisting(true);

    const userId = _.trim(userEmail);
    const existingMember = _.find(orgMembers, { userId });
    if (existingMember) {
      try {
        await OrgService.addOrgAdmin(existingMember);
      } catch (e) {
        logger.error('Could not add existing user as org admin', e);
      }
    } else {
      // adding new member
      try {
        await OrgService.addOrgMember({ orgId, userId, type: ORG_ADMIN });
      } catch (e) {
        logger.error('Could not add new org admin user', e);

        // It's possible that there is already an org user record for this user
        // but we didn't get it back in the query for all org users. At this point we
        // can attempt to update the existing org user record to an admin role
        try {
          logger.info('Attempting to update existing org user record to admin role');
          await OrgService.addOrgAdmin({ orgId, userId });
        } catch (ee) {
          logger.error('Unable to update existing org user record to admin role', ee)
        }
      }
    }

    setUserEmail('');
    setIsPersisting(false);
    setAddingMember(false);
  }

  function onEmailChange(email) {
    setUserEmail(email);

    if (!_.isEmpty(email)) {
      // eslint-disable-next-line no-useless-escape
      const valid = /[^\s]+@[^\s]+\.[^\s]+/.test(email);
      setEmailValid(valid);
      setEmailErrorMessage(valid ? ' ' : t`Enter a valid email address`);
    } else {
      setEmailValid(true);
      setEmailErrorMessage(' ');
    }
  }

  function getAddInputs() {
    if (!addingMember) {
      return null;
    }

    return (
      <div className={styles.inputContainer}>
        <div className={styles.addContainer}>
          <div className={styles.emailContainer}>
            <FormControl fullWidth>
              <Autocomplete
                id='org-admin-select'
                options={orgMembersPartition[1]}
                disableClearable
                freeSolo
                getOptionLabel={(option) => option?.userId || ''}
                value={selectedUser}
                onChange={(e, value) => {
                  setSelectedUser(value);
                  // onEmailChange(value);
                }}
                onInputChange={(e, inputVal) => {
                  onEmailChange(inputVal);
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className={cx(styles.memberItem, _.get(props, 'className'))}
                    key={`admin-list-user[${option.userId}]`}
                  >
                    <Avatar
                      className={styles.avatar}
                      key={option.userId}
                      size={36}
                      email={option.userId}
                      firstName={option.givenName}
                      lastName={option.familyName}
                    />
                    <div className={styles.nameContainer}>
                      {Boolean(option.givenName || option.familyName) && (
                        <span>{`${option.givenName} ${option.familyName}`}</span>
                      )}
                      <span
                        className={cx(
                          styles.email,
                          Boolean(option.givenName || option.familyName) && styles.nameVisible
                        )}
                      >
                        {option.userId}
                      </span>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...ORG.members.admin.add.email}
                    id='course-members-entry-field'
                    disabled={isPersisting}
                    error={!emailValid}
                    label='User email'
                    inputRef={roleRef}
                    inputProps={{
                      ...params.inputProps,
                      pattern: '[^s]+@[^s]+.[^s]+',
                    }}
                    maxLength={MAX_LENGTH}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={styles.actionsContainer}>
            {isPersisting ? (
              <Box className={styles.progressContainer}>
                <CircularProgress size={ACTION_ICON_SIZE} />
              </Box>
            ) : (
              <Tooltip title={t`Add admin`}>
                <span>
                  <IconButton
                    {...ORG.members.admin.add.submit}
                    aria-label={t`Add admin`}
                    disabled={!isEmailValid}
                    className={styles.addEmailButton}
                    onClick={onAddMember}
                  >
                    <BongoFontAwesomeIcon icon={faPlus} size={ACTION_ICON_SIZE} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title={t`Cancel`}>
              <span>
                <IconButton
                  {...ORG.members.admin.add.cancel}
                  aria-label={t`Cancel`}
                  className={styles.addEmailButton}
                  disabled={isPersisting}
                  onClick={() => {
                    setAddingMember(false);
                  }}
                >
                  <BongoFontAwesomeIcon icon={faXmark} size={ACTION_ICON_SIZE} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
        <div aria-live='polite' {...ORG.members.admin.add.errorMessage} className={styles.addError}>
          {emailErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ListItemButton
        {...ORG.members.admin.addAdmin}
        component='a'
        onClick={() => {
          setAddingMember(true);
        }}
        className={cx(styles.addButton, addingMember && styles.hidden)}
      >
        <BongoFontAwesomeIcon className={styles.addIcon} icon={faUserPlus} size={28} />
        <span className={styles.name}>{t`Add Admin`}</span>
      </ListItemButton>

      {getAddInputs()}
    </div>
  );
}

AddAdminInput.propTypes = {
  orgId: PropTypes.string.isRequired,
};
