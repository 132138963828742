import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ORG } from 'common/dattrs';
import { getOrgs } from 'redux/org/org.selectors';
import InstitutionListItem from './InstitutionListItem';
import styles from './InstitutionList.module.scss';

function InstitutionList(props) {
  const institutions = useSelector(getOrgs);

  return (
    <div className={styles.container}>
      <div {...ORG.list.columns} className={styles.columnLabelContainer}>
        <span className={styles.nameCol}>{t`Name`}</span>
      </div>
      <div className={styles.courseListContainer}>
        <div {...ORG.list.items} role='list' className={styles.courseList}>
          {_.map(institutions, (institution) => {
            return <InstitutionListItem key={`institution_${institution.id}`} institution={institution} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default InstitutionList;
