import { ADD_SNACKBAR_NOTIFICATION, CLOSE_SNACKBAR_NOTIFICATION } from './notifications.types';

export function addNotification(payload) {
  return {
    type: ADD_SNACKBAR_NOTIFICATION,
    payload,
  };
}

export function closeNotification(key) {
  return {
    type: CLOSE_SNACKBAR_NOTIFICATION,
    key,
  }
}

