import _ from 'lodash';

const DATA_BAT = 'data-bat';
const DATA_BAT_PREFIX = `${DATA_BAT}-`;

function dattr(tagName) {
  this[DATA_BAT] = tagName;
}

dattr.prototype.q = function(extraAttrs) {
  const dataAttrs = {
    [DATA_BAT]: this[DATA_BAT],
  };

  _.forEach(extraAttrs, (value, key) => {
    const _key = _.toLower(key);
    dataAttrs[`${DATA_BAT_PREFIX}${_key}`] = value;
  });

  return dataAttrs;
};

function isDattrProp(value, key) {
  if (key === DATA_BAT) return true;
  return _.startsWith(key, DATA_BAT_PREFIX);
}

function dattrsFromProps(props) {
  return _.pickBy(props, isDattrProp);
}

export { dattrsFromProps };
export default dattr;
