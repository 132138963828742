import React, { useState } from 'react';
import { t } from '@lingui/macro';

import IconButton from '@mui/material/IconButton';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import InstitutionSettingsDialog from 'components/institution/settings/InstitutionSettingsDialog';
import Permissions from 'common/utils/permissions';
import Tooltip from 'components/common/tooltip/Tooltip';
import { ORG } from 'common/dattrs';

import styles from './InstitutionActions.module.scss';

export default function InstitutionActions() {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const isInstitutionSettingsVisible = Permissions.isInstitutionSettingsVisible();

  if (!isInstitutionSettingsVisible) {
    return null;
  }

  return (
    <div className={styles.actions}>
      <Tooltip title={t`Settings`}>
        <IconButton
          {...ORG.settingsItem}
          onClick={() => {
            setSettingsVisible(true);
          }}
        >
          <BongoFontAwesomeIcon size={24} icon={faGear} />
        </IconButton>
      </Tooltip>

      {settingsVisible && (
        <InstitutionSettingsDialog
          onHide={() => {
            setSettingsVisible(false);
          }}
        />
      )}
    </div>
  );
}
