import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Highlight = (props) => {
  const { children, search } = props;
  if (_.isEmpty(search)) {
    return children;
  }

  const regex = new RegExp(`(${_.escapeRegExp(search)})`, 'gi');
  const parts = children.split(regex);
  return (
    <span aria-label={children}>
      {parts
        .filter((part) => part)
        .map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>))}
    </span>
  );
};

Highlight.propTypes = {
  children: PropTypes.string.isRequired,
  search: PropTypes.string,
};

Highlight.defaultProps = {
  search: null,
};

export default Highlight;
