import {
  ADD_COURSE,
  ADD_COURSE_MEMBER,
  DELETE_COURSE_MEMBER,
  SET_COURSES,
  SET_ACTIVE_COURSE_MEMBERS,
  SET_ACTIVE_COURSE,
  UPDATE_COURSE_SETTINGS,
  UPDATE_COURSE_MEMBER,
  SET_COURSE_SORT_FIELD,
  SET_COURSE_SORT_DIRECTION,
  SET_COURSE_FILTER_FIELD_VALUE,
  UPDATE_COURSE_FILTERS,
  CLEAR_COURSE_FILTERS,
  ADD_COPIED_COURSE,
  UPDATE_COURSE_COPY_PROGRESS,
  CLEAR_COURSE_COPY_INITIATED_FLAG,
} from './course.types';

export function setCourses(payload) {
  return {
    type: SET_COURSES,
    payload,
  };
}

export function setActiveCourse(payload) {
  return {
    type: SET_ACTIVE_COURSE,
    payload,
  };
}

export function setActiveCourseMembers(id, payload) {
  return {
    id,
    type: SET_ACTIVE_COURSE_MEMBERS,
    payload,
  };
}

export function updateCourseSettings(payload) {
  return {
    type: UPDATE_COURSE_SETTINGS,
    payload,
  };
}

export function addCourse(payload) {
  return {
    type: ADD_COURSE,
    payload,
  };
}

export function addCourseMember(payload) {
  return {
    type: ADD_COURSE_MEMBER,
    payload,
  };
}

export function updateCourseMember(payload) {
  return {
    type: UPDATE_COURSE_MEMBER,
    payload,
  };
}

export function deleteCourseMember(payload) {
  return {
    type: DELETE_COURSE_MEMBER,
    payload,
  };
}

export function setCourseSortField(value) {
  return {
    type: SET_COURSE_SORT_FIELD,
    value,
  };
}

export function setCourseSortDirection(value) {
  return {
    type: SET_COURSE_SORT_DIRECTION,
    value,
  };
}

export function setCourseFilterFieldValue(value) {
  return {
    type: SET_COURSE_FILTER_FIELD_VALUE,
    value,
  };
}

export function updateCourseFilters(filters) {
  return {
    type: UPDATE_COURSE_FILTERS,
    filters,
  };
}

export function clearCourseFilters() {
  return {
    type: CLEAR_COURSE_FILTERS,
  };
}

export function addCopiedCourse(payload) {
  return {
    type: ADD_COPIED_COURSE,
    payload,
  };
}

export function updateCourseCopyProgress(payload) {
  return {
    type: UPDATE_COURSE_COPY_PROGRESS,
    payload,
  }
}

export function clearCourseCopyInitiatedFlag() {
  return {
    type: CLEAR_COURSE_COPY_INITIATED_FLAG,
  };
}