import React from 'react';

import bongoLogo from '../assets/images/bongo.svg';
import styles from './BongoLogo.module.scss';

function BongoLogo() {
  return <img className={styles.bongoLogo} alt='Bongo Logo' src={bongoLogo} />;
}

export default BongoLogo;
