import React, { useEffect, useState } from 'react';
import { t, Plural } from '@lingui/macro';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Button from '@mui/material/Button';

import { ORG } from 'common/dattrs';
import EmptyInstitutionList from 'components/institution/EmptyInstitutionList';
import InstitutionCreateDialog from 'components/institution/InstitutionCreateDialog';
import InstitutionList from 'components/institution/InstitutionList';
import OrgService from 'service/org';
import Loader from 'components/common/loader/Loader';
import { getOrgs } from 'redux/org/org.selectors';

import Page from './Page';
import styles from './Institutions.module.scss';

function Institutions(props) {
  const orgs = useSelector(getOrgs);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loadingInstitutions, setLoadingInstitutions] = useState(true);

  function institutionListEmpty() {
    return !loadingInstitutions && _.isEmpty(orgs);
  }

  function getContent() {
    if (loadingInstitutions) {
      return null;
    }

    if (institutionListEmpty()) {
      return (
        <EmptyInstitutionList
          onCreate={() => {
            setCreateDialogVisible(true);
          }}
        />
      );
    }

    return <InstitutionList />;
  }

  function getPageActions() {
    if (loadingInstitutions || _.isEmpty(orgs)) {
      return null;
    }

    return (
      <div className={styles.pageActions}>
        <Button
          {...ORG.create}
          variant='contained'
          onClick={() => {
            setCreateDialogVisible(true);
          }}
        >{t`Create Institution`}</Button>
      </div>
    );
  }

  useEffect(() => {
    async function fetchData() {
      await OrgService.fetchOrgs();
      setLoadingInstitutions(false);
    }

    fetchData();
  }, []);

  function getPageDescription() {
    if (_.isEmpty(orgs)) {
      return null;
    }

    const orgCount = orgs.length;
    return (
      <div>
        <span {...ORG.count} className={styles.courseCount}>
          {orgCount}
        </span>
        <span>
          <Plural value={orgCount} one='institution' other='institutions'></Plural>
        </span>
      </div>
    );
  }

  function getBreadcrumbs() {
    return [];
  }

  return (
    <Page title={t`Institutions`} breadcrumbs={getBreadcrumbs()} description={getPageDescription()} actions={getPageActions()}>
      <Loader visible={loadingInstitutions} />
      {getContent()}
      {createDialogVisible && <InstitutionCreateDialog onHide={() => setCreateDialogVisible(false)} />}
    </Page>
  );
}

export default Institutions;
