import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { t } from '@lingui/macro';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons/faBarsFilter';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';

import styles from './FilterField.module.scss';

const FilterField = (props) => {
  const { children, filterCount } = props;
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);

  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  return (
    <div className={styles.container}>
      <Button
        className={cx(styles.filterButton, Boolean(filterCount) && styles.hasFilters)}
        variant='outlined'
        color={filterCount ? 'primary' : 'inherit'}
        aria-label={t`filter options`}
        aria-controls={filterAnchorEl ? 'filter-options-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={filterAnchorEl ? 'true' : undefined}
        onClick={handleFilterMenuOpen}
        startIcon={<BongoFontAwesomeIcon icon={faBarsFilter} size={16} />}
        endIcon={
          <div className={styles.filterIconContainer}>
            {filterCount ? (
              <div className={styles.filterCountContainer}>
                <span>{filterCount}</span>
              </div>
            ) : (
              <BongoFontAwesomeIcon icon={faAngleDown} size={12} />
            )}
          </div>
        }
      >
        {t`Filters`}
      </Button>

      <Popover
        id={'filter-options-menu'}
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

FilterField.propTypes = {
  filterCount: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FilterField;
