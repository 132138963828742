import _ from 'lodash';

import dattr, { dattrsFromProps } from './dattr';
import dattrTree from './dattr-tree';

const KEY_SEPARATOR = '-';

function traverse(tree, currentPath = []) {
  _.forOwn(tree, (value, key) => {
    const newPath = [...currentPath, key];
    if (_.isObject(value)) {
      traverse(value, newPath);
    } else {
      const tagName = _.join(newPath, KEY_SEPARATOR);
      _.set(tree, key, new dattr(tagName));
    }
  });
}
const tree = _.clone(dattrTree);
traverse(tree);

const ACTIVITY = tree.activity;
const COURSE = tree.course;
const DIALOG = tree.dialog;
const GENERAL = tree.general;
const ORG = tree.org;
const LOGIN = tree.login;
const PAGE = tree.page;
const USER = tree.user;
const HELP = tree.help;

export default tree;
export {
  ACTIVITY,
  COURSE,
  DIALOG,
  GENERAL,
  ORG,
  LOGIN,
  PAGE,
  USER,
  HELP,
};
export { dattrsFromProps };
