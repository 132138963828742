import React from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';

import FilterField from 'components/common/input/FilterField';
import Permissions from 'common/utils/permissions';
import { AUTO_ENROLLMENT_ACTIVE_FIELD, AUTO_ENROLLMENT_INACTIVE_FIELD, ROLES } from 'common/constants/general';
import { getCourseFilters, getCourseFilterFieldValue, getRawCourses } from 'redux/course/course.selectors';
import { updateCourseFilters, setCourseFilterFieldValue } from 'redux/course/course.actions';

import CourseListFilterInput from './CourseListFilterInput';
import styles from './CourseListFilterField.module.scss';

const CourseListFilterField = () => {
  const filters = useSelector(getCourseFilters);
  const filterValue = useSelector(getCourseFilterFieldValue);
  const dispatch = useDispatch();
  const mediumWidth = useMediaQuery('(min-width:700px)');
  const rawCourses = useSelector(getRawCourses);
  const isCourseRoleFiltersVisible = Permissions.isCourseRoleFilterVisible(rawCourses);
  const isCourseAutoEnrollmentStatusVisible = Permissions.isCourseAutoEnrollmentStatusVisibleForAnyCourse(rawCourses);

  function isFilterChecked(section, name) {
    return _.get(filters, section, []).includes(name);
  }

  function onRoleFilterChanged(name, event) {
    const { role } = filters;
    let roles = [...role];

    if (event.target.checked) {
      roles.push(name);
    } else {
      _.remove(roles, function (item) {
        return item === name;
      });
    }

    dispatch(updateCourseFilters({ role: _.uniq(roles), autoEnrollment: [...filters.autoEnrollment] }));
  }

  function onAutoEnrollmentFilterChanged(name, event) {
    const { autoEnrollment } = filters;
    let enrollmentFilters = [...autoEnrollment];

    if (event.target.checked) {
      enrollmentFilters.push(name);
    } else {
      _.remove(enrollmentFilters, function (item) {
        return item === name;
      });
    }

    dispatch(updateCourseFilters({ role: [...filters.role], autoEnrollment: _.uniq(enrollmentFilters) }));
  }

  function getFilterCount() {
    let count = filters.role.length + filters.autoEnrollment.length;
    if (!mediumWidth && !_.isEmpty(filterValue)) {
      count++;
    }
    return count;
  }

  function onClearFilters() {
    dispatch(updateCourseFilters({ role: [], autoEnrollment: [] }));
    if (!mediumWidth) {
      dispatch(setCourseFilterFieldValue(''));
    }
  }

  function createRoleFilterItem(roleItem) {
    const { label, value } = roleItem;
    return (
      <FormControlLabel
        key={`courseRoleFilterItem[${value}]`}
        control={
          <Checkbox
            checked={isFilterChecked('role', value)}
            onChange={(e) => {
              onRoleFilterChanged(value, e);
            }}
            name={value}
          />
        }
        label={i18n._(label)}
      />
    );
  }

  if (!isCourseRoleFiltersVisible && !isCourseAutoEnrollmentStatusVisible && mediumWidth) {
    return null;
  }

  return (
    <FilterField filterCount={getFilterCount()}>
      <div className={styles.filterContainer}>
        <div className={styles.header}>
          <h3 className={styles.title}>{t`Filters`}</h3>
          <Button
            className={styles.clearButton}
            variant='outlined'
            color='primary'
            size='small'
            disabled={!getFilterCount()}
            aria-label={t`clear filter options`}
            onClick={onClearFilters}
          >
            {t`Clear`}
          </Button>
        </div>
        <Divider className={styles.headerDivider} />
        <section>{!mediumWidth && <CourseListFilterInput className={styles.filterInput} />}</section>
        {isCourseAutoEnrollmentStatusVisible && (
          <section className={cx(styles.filterSection, styles.typeOptions)}>
            <h4>{t`Auto Enrollment`}</h4>
            <FormControl className={styles.filterSectionContent}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFilterChecked('autoEnrollment', AUTO_ENROLLMENT_ACTIVE_FIELD)}
                      onChange={(e) => {
                        onAutoEnrollmentFilterChanged(AUTO_ENROLLMENT_ACTIVE_FIELD, e);
                      }}
                      name={AUTO_ENROLLMENT_ACTIVE_FIELD}
                    />
                  }
                  label={t`Active`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFilterChecked('autoEnrollment', AUTO_ENROLLMENT_INACTIVE_FIELD)}
                      onChange={(e) => {
                        onAutoEnrollmentFilterChanged(AUTO_ENROLLMENT_INACTIVE_FIELD, e);
                      }}
                      name={AUTO_ENROLLMENT_INACTIVE_FIELD}
                    />
                  }
                  label={t`Inactive`}
                />
              </FormGroup>
            </FormControl>
          </section>
        )}

        {isCourseRoleFiltersVisible && (
          <section className={cx(styles.filterSection, styles.visibilityOptions)}>
            <h4>{t`Role`}</h4>
            <FormControl className={styles.filterSectionContent}>
              <FormGroup>{ROLES.map((role) => createRoleFilterItem(role))}</FormGroup>
            </FormControl>
          </section>
        )}
      </div>
    </FilterField>
  );
};

export default CourseListFilterField;
