import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from 'redux/general/general.actions';
import logger from 'common/utils/logger';

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function _logout() {
      try {
        await Auth.signOut();
      } catch (e) {
        logger.error('Error logging out', e);
      }

      navigate('/login');
      dispatch(logout());
      window.location.reload();
    }
    _logout();
  }, []); // eslint-disable-line

  return <div></div>;
}

export default Logout;
