export const SET_COURSES = 'course/SET_COURSES';
export const SET_ACTIVE_COURSE_MEMBERS = 'course/SET_ACTIVE_COURSE_MEMBERS';
export const SET_ACTIVE_COURSE = 'course/SET_ACTIVE_COURSE';
export const UPDATE_COURSE_SETTINGS = 'course/UPDATE_COURSE_SETTINGS';
export const ADD_COURSE = 'course/ADD_COURSE';
export const ADD_COURSE_MEMBER = 'course/ADD_COURSE_MEMBER';
export const UPDATE_COURSE_MEMBER = 'course/UPDATE_COURSE_MEMBER';
export const DELETE_COURSE_MEMBER = 'course/DELETE_COURSE_MEMBER';
export const SET_COURSE_SORT_FIELD = 'course/SET_COURSE_SORT_FIELD';
export const SET_COURSE_SORT_DIRECTION = 'course/SET_COURSE_SORT_DIRECTION';
export const SET_COURSE_FILTER_FIELD_VALUE = 'course/SET_COURSE_FILTER_FIELD_VALUE';
export const UPDATE_COURSE_FILTERS = 'course/UPDATE_COURSE_FILTERS';
export const CLEAR_COURSE_FILTERS = 'course/CLEAR_COURSE_FILTERS';
export const ADD_COPIED_COURSE = 'course/ADD_COPIED_COURSE';
export const UPDATE_COURSE_COPY_PROGRESS = 'course/UPDATE_COURSE_COPY_PROGRESS';
export const CLEAR_COURSE_COPY_INITIATED_FLAG = 'course/CLEAR_COURSE_COPY_INITIATED_FLAG';

