import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { dattrsFromProps } from 'common/dattrs';
import styles from './LoadingButton.module.scss';
function LoadingButton(props) {
  const { children, loading, loadingMessage, variant, ...rest } = props;
  return (
    <Button {...dattrsFromProps(props)} variant={variant} {...rest}>
      {loading ? (
        <div className={styles.progressContainer}>
          <CircularProgress size={24} />
          {loadingMessage && <span className={styles.message}>{loadingMessage}</span>}
        </div>
      ) : (
        children
      )}
    </Button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  variant: PropTypes.string,
};

LoadingButton.defaultProps = {
  loading: false,
  variant: 'outlined',
};

export default LoadingButton;
