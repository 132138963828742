import React from 'react';
import { t } from '@lingui/macro';

import { ORG } from 'common/dattrs';
import ActivityListOverview from 'components/common/ActivityListOverview';
import styles from './MissingInstitution.module.scss';

function MissingInstitution(props) {
  return (
    <>
      <div {...ORG.notSetupContainer} className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>{t`No Courses Available`}</h1>
          <p>{t`You don't currently have access to any courses. You need to be added to at least one course before you can start using Bongo. Please contact the instructor or admin at your organization in order to be added to a course.`}</p>
        </div>
      </div>
      <ActivityListOverview />
    </>
  );
}

export default MissingInstitution;
