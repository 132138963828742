import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import { ORG } from 'common/dattrs';
import OrgService from 'service/org';
import { getOrgs, getCurrentOrg } from 'redux/org/org.selectors';
import { isSuperUser } from 'redux/user/user.selectors';

const StyledSelect = styled(Select)({
  maxWidth: 200,
  '&:before': {
    border: 'none',
  },
  '.MuiSelect-select': {
    paddingLeft: 4,
  },
});

function InstitutionBreadcrumb() {
  const orgs = useSelector(getOrgs);
  const org = useSelector(getCurrentOrg);
  const _isSuperUser = useSelector(isSuperUser);
  const navigate = useNavigate();

  const onOrgChange = (e) => {
    const orgId = e.target.value;
    if (_isSuperUser) {
      const path = generatePath(`/institutions/:institutionId/courses`, {
        institutionId: orgId,
      });
      navigate(path);
    } else {
      OrgService.setCurrentOrg(orgId);
      navigate('/courses');
    }
  };

  if (_.isEmpty(orgs)) {
    return null;
  }

  if (_.isEmpty(org)) {
    return <span>{t`Loading...`}</span>;
  }

  if (orgs.length <= 1) {
    return <span>{org.name}</span>;
  }

  return (
    <StyledSelect
      {...ORG.breadcrumb.select}
      id='org-selection-field'
      aria-label={t`Choose institution`}
      label={t`Role`}
      disabled={false}
      variant={'standard'}
      size='small'
      value={org.id}
      onChange={onOrgChange}
    >
      {orgs.map((i) => (
        <MenuItem {...ORG.breadcrumb.selectItem.q({ id: i.id, name: i.name })} key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export default InstitutionBreadcrumb;
