import React, { useEffect, useLayoutEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash';

import Button from '@mui/material/Button';
import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons/faLinkSimple';
import useMediaQuery from '@mui/material/useMediaQuery';

import ActivityList from 'components/activity/ActivityList';
import ActivityService from 'service/activity';
import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import ActivitySettingsDialog from 'components/activity/ActivitySettingsDialog';
import CourseActions from 'components/course/CourseActions';
import CourseMemberAvatars from 'components/course/CourseMemberAvatars';
import CourseService from 'service/course';
import CourseCopyDialog from 'components/course/copy/CourseCopyDialog';
import CreateActivityDialog from 'components/activity/CreateActivityDialog';
import EmptyListMessage from 'components/EmptyListMessage';
import EnrollmentStatus from 'components/course/EnrollmentStatus';
import InlineMessage from 'components/InlineMessage';
import OrgService from 'service/org';
import InstitutionBreadcrumb from 'components/institution/InstitutionBreadcrumb';
import Loader from 'components/common/loader/Loader';
import LoadingIconButton from 'components/common/button/LoadingIconButton';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import Permissions from 'common/utils/permissions';
import Tooltip from 'components/common/tooltip/Tooltip';

import { ACTIVITY } from 'common/dattrs';
import { copyAsyncResultsToClipboard } from 'common/utils/domUtils';
import { isSuperUser } from 'redux/user/user.selectors';
import { getCurrentOrg } from 'redux/org/org.selectors';
import { getActiveCourse, getActiveCourseMembers } from 'redux/course/course.selectors';
import { getActivities, getRawActivitiesCount } from 'redux/activity/activity.selectors';

import Page from './Page';
import styles from './Course.module.scss';

function Course() {
  const { courseId } = useParams();
  const _isSuperUser = useSelector(isSuperUser);
  const org = useSelector(getCurrentOrg);
  const activities = useSelector(getActivities);
  const rawActivitiesCount = useSelector(getRawActivitiesCount);
  const courseMembers = useSelector(getActiveCourseMembers(courseId));
  const course = useSelector(getActiveCourse);
  const showActionsInline = useMediaQuery('(min-width:600px)');

  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [bongoDialogVisible, setBongoDialogVisible] = useState(false);
  const [createActivityType, setCreateActivityType] = useState(null);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [copyCourseVisible, setCopyCourseVisible] = useState(false);

  async function fetchData() {
    setLoadingActivities(true);
    if (courseId) {
      await Promise.all([CourseService.fetchCourse(courseId), ActivityService.fetchActivities(courseId)]);
    }
    setLoadingActivities(false);
  }

  useLayoutEffect(() => {
    fetchData();

    return () => {
      ActivityService.clearActivityFilters();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!_.isEmpty(course)) {
      OrgService.fetchOrg(course.orgId);

      if (Permissions.isCourseMembershipVisible(course?.role)) {
        CourseService.loadCourseMembers(course.id);
      }
    }
  }, [course?.id]); //eslint-disable-line

  async function onCourseLinkCopy() {
    setGeneratingLink(true);
    try {
      await copyAsyncResultsToClipboard(CourseService.generateLink({ courseId }));
      NotificationService.notify({ message: t`Course link copied` });
    } catch (e) {
      NotificationService.notify({ message: t`Could not copy course link`, severity: NOTIFICATION_TYPES.ERROR });
    }
    setGeneratingLink(false);
  }

  function getCourseComponent() {
    if (loadingActivities) {
      return null;
    }

    if (_.isEmpty(course)) {
      return (
        <InlineMessage
          title={t`Error fetching course`}
          message={t`Something went wrong getting information for this course`}
        />
      );
    }

    if (!rawActivitiesCount) {
      if (Permissions.isActivityCreationVisible(course?.role)) {
        return (
          <EmptyListMessage
            title={t`Start by creating an assignment`}
            description={t`Currently this course is empty. Once an assignment is created, learners can begin work.`}
            action={
              <Button
                {...ACTIVITY.create}
                className={styles.createButton}
                variant='contained'
                onClick={() => {
                  setCreateDialogVisible(true);
                }}
              >{t`Create Assignment`}</Button>
            }
          />
        );
      } else {
        return (
          <EmptyListMessage
            title={t`No assignments yet`}
            description={t`Looks like you don't have any assignments yet for this course. Once some are created, they will show up here.`}
            onCreateActivity={() => {
              setCreateDialogVisible(true);
            }}
          />
        );
      }
    }

    return (
      <div className={styles.courseContents}>
        <div className={styles.activities}>
          <ActivityList activities={activities} courseId={courseId} />
        </div>
      </div>
    );
  }

  function getBreadcrumbs() {
    // put in a placeholder link in case something goes wrong loading the course
    if (_.isEmpty(course) || !org) {
      return [<Link key={'courses'} to={'/courses'}>{t`Courses`}</Link>];
    }

    if (_isSuperUser) {
      return [
        <Link key={'institutions'} to={'/institutions'}>{t`Institutions`}</Link>,
        <InstitutionBreadcrumb key={'org'} />,
        <Link key={'courses'} to={`/institutions/${org.id}/courses`}>{t`Courses`}</Link>,
      ];
    }

    return [<InstitutionBreadcrumb key={'org'} />, <Link key={'courses'} to={'/courses'}>{t`Courses`}</Link>];
  }

  function getActions() {
    return (
      <CourseActions
        courseId={courseId}
        role={course?.role}
        onCourseLinkCopy={onCourseLinkCopy}
        onCopyCourse={() => {
          setCopyCourseVisible(true);
        }}
      />
    );
  }

  function getTitleActions() {
    if (!Permissions.isCourseCopyLinkVisible(course?.role) || !showActionsInline) {
      return null;
    }

    return (
      <Tooltip title={t`Copy course link`}>
        <div>
          <LoadingIconButton aria-label={t`Copy course link`} loading={generatingLink} onClick={onCourseLinkCopy}>
            <BongoFontAwesomeIcon size={24} icon={faLinkSimple} />
          </LoadingIconButton>
        </div>
      </Tooltip>
    );
  }

  function getDescription() {
    const membersVisible = Permissions.isCourseMembershipVisible(course?.role);
    const enrollmentVisible = Permissions.isCourseAutoEnrollmentStatusVisible(course?.role);
    if (!membersVisible && !enrollmentVisible) {
      return null;
    }

    return (
      <div className={styles.courseDetails}>
        {enrollmentVisible && <EnrollmentStatus autoEnrollment={_.get(course, 'autoEnrollment')} />}
        {membersVisible && (
          <div className={styles.memberInfo}>
            {enrollmentVisible && <span className={styles.sep}></span>}
            <CourseMemberAvatars courseMembers={courseMembers} />
          </div>
        )}
      </div>
    );
  }

  return (
    <Page
      className={styles.page}
      title={_.get(course, 'name', '')}
      actions={getActions()}
      titleActions={getTitleActions()}
      actionsClassName={styles.actions}
      description={getDescription()}
      breadcrumbs={getBreadcrumbs()}
    >
      <Loader visible={loadingActivities} />

      {createDialogVisible && (
        <CreateActivityDialog
          onCreate={(type) => {
            setCreateDialogVisible(false);
            setCreateActivityType(type);
            setBongoDialogVisible(true);
          }}
          onHide={() => {
            setCreateDialogVisible(false);
          }}
        />
      )}

      {bongoDialogVisible && (
        <ActivitySettingsDialog
          courseId={courseId}
          type={createActivityType}
          onHide={() => {
            setBongoDialogVisible(false);
            fetchData();
          }}
        />
      )}

      {copyCourseVisible && (
        <CourseCopyDialog
          course={course}
          activities={activities}
          onHide={() => {
            setCopyCourseVisible(false);
          }}
        />
      )}

      {getCourseComponent()}
    </Page>
  );
}

export default Course;
