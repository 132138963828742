import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { PAGE } from 'common/dattrs';

function Loader(props) {
  const { color, visible } = props;

  return (
    <Backdrop {...PAGE.loader} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible}>
      <CircularProgress color={color} />
    </Backdrop>
  );
}

Loader.propTypes = {
  visible: PropTypes.bool,
  color: PropTypes.string,
};

Loader.defaultProps = {
  visible: true,
  color: 'inherit',
};

export default Loader;
