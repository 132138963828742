import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import FilterInput from 'components/common/input/FilterInput';
import { getCourseFilterFieldValue } from 'redux/course/course.selectors';
import { setCourseFilterFieldValue } from 'redux/course/course.actions';

const CourseListFilterInput = (props) => {
  const { className } = props;
  const filterValue = useSelector(getCourseFilterFieldValue);
  const dispatch = useDispatch();

  return (
    <FilterInput
      className={className}
      placeholder={t`Find by course name`}
      value={filterValue}
      onValueChange={(val) => {
        dispatch(setCourseFilterFieldValue(val));
      }}
    />
  );
};

CourseListFilterInput.propTypes = {
  className: PropTypes.string,
};

export default CourseListFilterInput;
