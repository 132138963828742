const dattrTree = {
  activity: {
    create: null,
    createDialog: {
      activity: null,
      close: null,
      container: null,
    },
    deleteDialog: {
      delete: null,
    },
    backToActivityList: null,
    list: {
      columns: null,
      items: null,
      item: {
        launch: null,
        menu: null,
        configure: null,
        copy: null,
        copyLink: null,
        delete: null,
        downloadSCORM: null,
        visibility: null,
        visibilityConfirm: null,
        visibilityCancel: null,
      },
    },
    copy: {
      dialog: null,
      submit: null,
      activityName: null,
      courseName: null,
    },
  },
  course: {
    count: null,
    create: null,
    options: null,
    settingsItem: null,
    createDialog: {
      input: null,
      create: null,
    },
    deleteDialog: {
      delete: null,
    },
    list: {
      columns: null,
      items: null,
      item: {
        autoEnrollment: null,
        copy: null,
        launch: null,
        menu: null,
        members: null,
        edit: null,
      },
    },
    settings: {
      save: null,
      name: null,
      copyLink: null,
      autoEnrollment: {
        toggle: null,
        startDate: null,
        endDate: null,
        validationMsg: null,
      },
    },
    members: {
      role: null,
      addMember: null,
      add: {
        email: null,
        role: null,
        submit: null,
        cancel: null,
        errorMessage: null,
      },
      list: null,
      pagination: null,
      memberItem: null,
      member: {
        edit: null,
        remove: null,
        cancel: null,
        save: null,
        removeConfirmDialog: null,
        removeConfirmBtn: null,
      },
    },
  },
  dialog: {
    close: null,
    title: null,
  },
  general: {
    activityInfo: null,
    bongoLink: null,
    emptyList: null,
    confirm: {
      accept: null,
      decline: null,
    },
  },
  org: {
    options: null,
    breadcrumb: {
      select: null,
      selectItem: null,
    },
    emptyContainer: null,
    count: null,
    create: null,
    createDialog: {
      create: null,
      input: null,
    },
    list: {
      columns: null,
      items: null,
      item: {
        launch: null,
      },
    },
    members: {
      admin: {
        addAdmin: null,
        add: {
          email: null,
          errorMessage: null,
          cancel: null,
          submit: null,
        }
      }
    },
    settingsItem: null,
    notSetupContainer: null,
    admin: {
      list: null,
    },
  },
  logo: null,
  login: null,
  help: null,
  page: {
    actions: null,
    breadcrumbs: null,
    description: null,
    loader: null,
    title: null,
    titleActions: null,
  },
  user: {
    fullname: null,
    email: null,
    options: null,
    logout: null,
  },
};

export default dattrTree;
