import { combineReducers } from 'redux';

import activityReducer from './activity/activity.reducer';
import browserReducer from './browser/browser.reducer';
import coursesReducer from './course/course.reducer';
import orgReducer from './org/org.reducer';
import userReducer from './user/user.reducer';
import notificationReducer from './notifications/notifications.reducer';
import { USER_LOGOUT } from './general/general.types';

const appReducer = combineReducers({
  activity: activityReducer,
  browser: browserReducer,
  courses: coursesReducer,
  org: orgReducer,
  user: userReducer,
  notifications: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
