import _ from 'lodash';

function getBrowserLocale() {
  return window.Intl && typeof window.Intl.DateTimeFormat === 'function' ? window.Intl.DateTimeFormat().resolvedOptions().locale : null;
}

export default function getBrowserLocales() {
  let browserLocales = [];
  if (!_.isEmpty(navigator.languages)) {
    browserLocales = [...navigator.languages];
  } else {
    browserLocales.push(navigator.language);
  }
  browserLocales.push(getBrowserLocale());

  return _.compact(browserLocales);
}
