import _ from 'lodash';
import {
  ADD_COURSE,
  ADD_COURSE_MEMBER,
  DELETE_COURSE_MEMBER,
  SET_COURSES,
  SET_ACTIVE_COURSE_MEMBERS,
  SET_ACTIVE_COURSE,
  UPDATE_COURSE_SETTINGS,
  UPDATE_COURSE_MEMBER,
  SET_COURSE_FILTER_FIELD_VALUE,
  SET_COURSE_SORT_FIELD,
  SET_COURSE_SORT_DIRECTION,
  UPDATE_COURSE_FILTERS,
  CLEAR_COURSE_FILTERS,
  ADD_COPIED_COURSE,
  UPDATE_COURSE_COPY_PROGRESS,
  CLEAR_COURSE_COPY_INITIATED_FLAG,
} from './course.types';

import { COURSE_DEFAULT_SORT_FIELD, COURSE_DEFAULT_SORT_DIRECTION } from './course.constants';
import { isAutoEnrollmentActive } from 'common/utils/course';
import { AUTO_ENROLLMENT_ACTIVE_FIELD, AUTO_ENROLLMENT_INACTIVE_FIELD } from 'common/constants/general';
import { COPY_STATES } from 'common/constants/copyStates';
import enums from 'graphql/enums';

const INITIAL_STATE = {
  activeCourse: {},
  activeCourseMembers: {},
  courses: [],
  rawCourses: [],
  coursesInCopyProgress: [],
  coursesCopySuccess: [],
  userInitiatedCourseCopy: false,
  rowsPerPage: 10,
  currentPage: 1,
  pageCount: 1,
  coursePage: [],
  filterValue: '',
  filters: {
    role: [],
    autoEnrollment: [],
  },
  sortColumn: COURSE_DEFAULT_SORT_FIELD,
  sortDirection: COURSE_DEFAULT_SORT_DIRECTION,
};

const getSortedFilteredCourseList = (state) => {
  const { rawCourses, filterValue, filters } = state;
  let _rawCourses = [...rawCourses];
  let _filterValue = _.trim(filterValue);

  if (!_.isEmpty(_.trim(_filterValue))) {
    _rawCourses = _.filter(_rawCourses, (course) => {
      const { name } = course;
      const matchesName = Boolean(name.toLowerCase().indexOf(_filterValue.toLowerCase()) >= 0);
      return matchesName;
    });
  }

  if (!_.isEmpty(filters.role)) {
    _rawCourses = _.filter(_rawCourses, (course) => {
      const { role } = course;
      return filters.role.includes(role);
    });
  }

  if (!_.isEmpty(filters.autoEnrollment)) {
    _rawCourses = _.filter(_rawCourses, (course) => {
      const { autoEnrollment } = course;
      if (filters.autoEnrollment.includes(AUTO_ENROLLMENT_ACTIVE_FIELD) && isAutoEnrollmentActive(autoEnrollment)) {
        return isAutoEnrollmentActive(autoEnrollment);
      } else if (
        filters.autoEnrollment.includes(AUTO_ENROLLMENT_INACTIVE_FIELD) &&
        !isAutoEnrollmentActive(autoEnrollment)
      ) {
        return true;
      }

      return false;
    });
  }

  if (state.sortColumn === 'autoEnrollment') {
    return _.orderBy(
      _rawCourses,
      [
        function (course) {
          if (course.role === enums.roles.LEARNER) {
            return null;
          }
          return !isAutoEnrollmentActive(_.get(course, 'autoEnrollment'));
        },
        'autoEnrollment.start',
        'autoEnrollment.end',
      ],
      [state.sortDirection, state.sortDirection, state.sortDirection]
    );
  }

  return _.orderBy(_rawCourses, [state.sortColumn], [state.sortDirection]);
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_COURSE: {
      const tempState = { ...state, rawCourses: [...state.courses, action.payload] };
      return {
        ...tempState,
        courses: getSortedFilteredCourseList(tempState),
      };
    }
    case ADD_COPIED_COURSE: {
      return {
        ...state,
        userInitiatedCourseCopy: true,
        coursesInCopyProgress: [...state.coursesInCopyProgress, action.payload],
      };
    }
    case CLEAR_COURSE_COPY_INITIATED_FLAG: {
      return { ...state, userInitiatedCourseCopy: false };
    }
    case SET_COURSES: {
      // do not include courses that are currently being copied. The user should not see those until they are "complete"
      const _rawCourses = [...action.payload];
      const filteredRawCourses = _.filter(_rawCourses, (course) => {
        const state = _.get(course, 'copyInfo.state');
        return !state || ![COPY_STATES.PENDING, COPY_STATES.IN_PROGRESS].includes(state);
      });

      // Check if the state has gone from "in progress" to "success". If so we want to store those off
      // separately so they can be presented to the user
      const tempState = { ...state, rawCourses: [...filteredRawCourses] };
      const currentCourseProgress = [...state.coursesInCopyProgress];
      const coursesCopySuccess = _.filter(_rawCourses, (course) => {
        return (
          [COPY_STATES.SUCCESS].includes(_.get(course, 'copyInfo.state')) &&
          _.find(currentCourseProgress, (c) => {
            return c.id === course.id;
          })
        );
      });

      // Get the courses that are "in progress" and store those separately for presenting progress
      const coursesInProgress = _.filter(_rawCourses, (course) => {
        return [COPY_STATES.PENDING, COPY_STATES.IN_PROGRESS].includes(_.get(course, 'copyInfo.state'));
      });

      return {
        ...tempState,
        courses: getSortedFilteredCourseList(tempState),
        // coursesInCopyProgress: [...coursesInProgress, action.payload[0], action.payload[1]],
        coursesInCopyProgress: [...coursesInProgress],
        coursesCopySuccess,
      };
    }
    case UPDATE_COURSE_COPY_PROGRESS: {
      const allCourses = [...action.payload];

      // Check if the state has gone from "in progress" to "success". If so we want to store those off
      // separately so they can be presented to the user
      const currentCourseProgress = [...state.coursesInCopyProgress];
      const coursesCopySuccess = _.filter(allCourses, (course) => {
        const copyState = _.get(course, 'copyInfo.state');
        const progressInstance = _.find(currentCourseProgress, (c) => {
          return c.id === course.id;
        });

        return (!copyState || copyState === COPY_STATES.SUCCESS) && progressInstance;
      });

      const coursesCopyFailed = _.filter(allCourses, (course) => {
        return (
          [COPY_STATES.FAILED].includes(_.get(course, 'copyInfo.state')) &&
          _.find(currentCourseProgress, (c) => {
            return c.id === course.id;
          })
        );
      });

      // Get the courses that are "in progress" and store those separately for presenting progress
      const coursesInProgress = _.filter(allCourses, (course) => {
        return [COPY_STATES.PENDING, COPY_STATES.IN_PROGRESS].includes(_.get(course, 'copyInfo.state'));
      });

      let tempState = { ...state };
      if (!_.isEmpty(coursesCopySuccess)) {
        // need to add these courses into the general course list
        tempState = {
          ...tempState,
          rawCourses: [...tempState.rawCourses, ...coursesCopySuccess],
        };
      }

      if (!_.isEmpty(coursesCopyFailed)) {
        // need to add these courses into the general course list
        tempState = {
          ...tempState,
          rawCourses: [...tempState.rawCourses, ...coursesCopyFailed],
        };
      }

      return {
        ...tempState,
        coursesInCopyProgress: [...coursesInProgress],
        coursesCopySuccess,
        courses: getSortedFilteredCourseList(tempState),
      };
    }
    case ADD_COURSE_MEMBER: {
      const { courseId } = action.payload;
      const _members = { ...state.activeCourseMembers };
      if (_members[courseId]) {
        _members[courseId] = [..._members[courseId], action.payload];
      } else {
        _members[courseId] = [action.payload];
      }
      return { ...state, activeCourseMembers: { ..._members } };
    }
    case SET_ACTIVE_COURSE_MEMBERS: {
      const _members = { ...state.activeCourseMembers };
      _members[action.id] = action.payload;
      return { ...state, activeCourseMembers: { ..._members } };
    }
    case SET_ACTIVE_COURSE:
      return { ...state, activeCourse: action.payload };
    case UPDATE_COURSE_MEMBER: {
      const { courseId, userId } = action.payload;
      const courseMembers = { ...state.activeCourseMembers };
      const members = [...courseMembers[courseId]];
      const memberIndex = _.findIndex(members, { userId });
      members[memberIndex] = { ...action.payload };
      courseMembers[courseId] = [...members];
      return { ...state, activeCourseMembers: { ...courseMembers } };
    }
    case DELETE_COURSE_MEMBER: {
      const { courseId, userId } = action.payload;
      const courseMembers = { ...state.activeCourseMembers };
      const members = [...courseMembers[courseId]];
      _.remove(members, (member) => {
        return member.userId === userId;
      });
      courseMembers[courseId] = [...members];
      return { ...state, activeCourseMembers: { ...courseMembers } };
    }
    case UPDATE_COURSE_SETTINGS: {
      const course = action.payload;
      const courses = [...state.courses];
      const courseIndex = _.findIndex(courses, { id: course.id });
      if (courseIndex !== -1) {
        courses[courseIndex] = { ...courses[courseIndex], ...course };
      }

      const rawCourses = [...state.rawCourses];
      const rawCourseIndex = _.findIndex(rawCourses, { id: course.id });
      if (rawCourseIndex !== -1) {
        rawCourses[rawCourseIndex] = { ...rawCourses[rawCourseIndex], ...course };
      }

      let activeCourse = { ...state.activeCourse };
      if (_.get(state, 'activeCourse.id') === course.id) {
        activeCourse = { ...activeCourse, ...course };
      }

      return { ...state, courses, rawCourses, activeCourse };
    }
    case SET_COURSE_SORT_FIELD: {
      const sortColumn = action.value;
      const tempState = {
        ...state,
        sortColumn,
      };

      return { ...tempState, courses: getSortedFilteredCourseList(tempState) };
    }
    case SET_COURSE_SORT_DIRECTION: {
      const sortDirection = action.value;
      const tempState = {
        ...state,
        sortDirection,
      };
      return { ...tempState, courses: getSortedFilteredCourseList(tempState) };
    }
    case SET_COURSE_FILTER_FIELD_VALUE: {
      const filterValue = action.value;
      const tempState = {
        ...state,
        filterValue,
      };
      return { ...tempState, courses: getSortedFilteredCourseList(tempState) };
    }
    case UPDATE_COURSE_FILTERS: {
      const tempState = {
        ...state,
        filters: action.filters,
      };
      return { ...tempState, courses: getSortedFilteredCourseList(tempState) };
    }
    case CLEAR_COURSE_FILTERS:
      return {
        ...state,
        filterValue: '',
        filters: {
          role: [],
          autoEnrollment: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
