import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import cx from 'classnames';
import _ from 'lodash';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { COURSE } from 'common/dattrs';
import CloseableDialog from 'components/common/dialog/CloseableDialog';
import CourseService from 'service/course';
import Loader from 'components/common/loader/Loader';
import logger from 'common/utils/logger';
import NotificationService, { NOTIFICATION_TYPES } from 'service/notification';
import { getActiveCourse } from 'redux/course/course.selectors';

import CourseLinkSection from './CourseLinkSection';
import CourseNameSection from './CourseNameSection';
import EnrollmentSection from './EnrollmentSection';
import Members from './Members';
import styles from './CourseSettingsDialog.module.scss';

function getDefaultEnrollmentState(course) {
  return {
    enabled: _.get(course, 'autoEnrollment.enabled', false),
    start: _.get(course, 'autoEnrollment.start', null),
    end: _.get(course, 'autoEnrollment.end', null),
  };
}

function CourseSettingsDialog(props) {
  const { courseId, onHide } = props;
  const [defaultEnrollmentState, setDefaultEnrollmentState] = useState(getDefaultEnrollmentState());
  const [enrollmentConfig, setEnrollmentConfig] = useState(getDefaultEnrollmentState());
  const [courseName, setCourseName] = useState('');
  const [settingsDirty, setSettingsDirty] = useState(false);
  const [courseNameValid, setCourseNameValid] = useState(true);
  const [enrollmentConfigValid, setEnrollmentConfigValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const course = useSelector(getActiveCourse);

  useEffect(() => {
    async function loadCourse() {
      setLoading(true);
      await CourseService.fetchCourse(courseId);
      setLoading(false);
    }

    loadCourse();
  }, [courseId]);

  useEffect(() => {
    const defaultState = getDefaultEnrollmentState(course);
    setDefaultEnrollmentState({ ...defaultState });
    setEnrollmentConfig({ ...defaultState });
    setCourseName(course.name);
  }, [course]);

  const performEnrollmentValidation = useCallback(() => {
    if (enrollmentConfig.enabled) {
      const { start, end } = enrollmentConfig;
      if (start && end && end <= start) {
        setEnrollmentConfigValid(false);
        return;
      }
    }

    setEnrollmentConfigValid(true);
  }, [enrollmentConfig]);

  const performCourseNameValidation = useCallback(() => {
    const isNameValid = !_.isEmpty(_.trim(courseName));
    if (!isNameValid) {
      setCourseNameValid(false);
      return;
    }

    setCourseNameValid(true);
  }, [courseName]);

  useEffect(() => {
    setSettingsDirty(_.trim(courseName) !== _.trim(course?.name));
    performCourseNameValidation();
  }, [courseName, course, performCourseNameValidation]);

  useEffect(() => {
    setSettingsDirty(!_.isEqual(enrollmentConfig, defaultEnrollmentState));
    performEnrollmentValidation();
  }, [enrollmentConfig, defaultEnrollmentState, performEnrollmentValidation]);

  async function onSave() {
    setLoading(true);
    const _config = {
      ...enrollmentConfig,
    };

    if (!_config.enabled) {
      _config.start = null;
      _config.end = null;
    }

    try {
      const result = await CourseService.updateCourseSettings({
        id: course.id,
        name: courseName,
        autoEnrollment: { ..._config },
      });

      setDefaultEnrollmentState({ ...result.autoEnrollment });
      setEnrollmentConfig({ ...result.autoEnrollment });
      setCourseName(result.name);
      setSettingsDirty(false);
    } catch (e) {
      logger.error('Could not save course settings', e);
      NotificationService.notify({
        key: 'copy-settings-error',
        message: t`Something went wrong trying to update this course. Please try again.`,
        variant: NOTIFICATION_TYPES.ERROR,
      });
    }

    setLoading(false);
  }

  return (
    <CloseableDialog
      id='course-settings-dialog'
      className={styles.dialog}
      contentClassName={styles.dialogContent}
      title={t`Settings`}
      fullScreen={true}
      fullWidth={true}
      maxWidth={'sm'}
      onHide={(event) => {
        onHide(event);
      }}
    >
      {loading && <Loader />}
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <Paper className={styles.paper} elevation={settingsDirty ? 3 : 0}>
            <div className={cx(styles.actions, settingsDirty && styles.visible)}>
              <Button
                {...COURSE.settings.save}
                disabled={!courseNameValid || !enrollmentConfigValid}
                variant='contained'
                onClick={onSave}
              >
                {t`Save Changes`}
              </Button>
            </div>
            <div className={styles.sections}>
              <div className={styles.nameSection}>
                <CourseNameSection
                  courseName={courseName}
                  onNameChanged={(val) => {
                    setCourseName(val);
                  }}
                />
              </div>
              <div className={styles.enrollmentSection}>
                <EnrollmentSection
                  enabled={enrollmentConfig.enabled}
                  start={enrollmentConfig.start}
                  end={enrollmentConfig.end}
                  onEnabledChanged={(enabled) => {
                    setEnrollmentConfig({ ...enrollmentConfig, enabled });
                  }}
                  onStartChanged={(start) => {
                    setEnrollmentConfig({ ...enrollmentConfig, start });
                  }}
                  onEndChanged={(end) => {
                    setEnrollmentConfig({ ...enrollmentConfig, end });
                  }}
                />
              </div>
            </div>
          </Paper>
          <div className={styles.sections}>
            <CourseLinkSection courseId={courseId} />
          </div>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.membersSection}>
            <Members courseId={courseId} />
          </div>
        </div>
      </div>
    </CloseableDialog>
  );
}

CourseSettingsDialog.propTypes = {
  courseId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CourseSettingsDialog;
