import getBrowserLocales from './browser';

const SUPPORTED_LANGUAGES = ['ar', 'de', 'en', 'es', 'fr', 'fr-CA', 'nl', 'pt-BR'];

// Regex to parse the locale string.
// This handles locale strings from the browser or from the php backend.
// From the backend, we get <language>_<region> ('pt_BR') or just language ('en').
// From the browser, we get BCP-47 locale strings...
// language[-script][-region]
// language: 2*3ALPHA => ([a-zA-Z]{2,3})
//           [-extlang] => (?:-[a-zA-Z]{3})?
// [-script]: -4ALPHA => (?:-[a-zA-Z]{4})?
// [-region]: 2ALPHA | 3DIGIT => (?:[-_]([a-zA-Z]{2}|[0-9]{3}))?
const localeRegex = /([a-zA-Z]{2,3})(?:-[a-zA-Z]{3})?(?:-[a-zA-Z]{4})?(?:[-_]([a-zA-Z]{2}|[0-9]{3}))?/;

// export function getOrderedLocales(localeObject) {
//   const orderedLocales = [];
//   if (localeObject && localeObject.frontend) {
//     orderedLocales.push(localeObject.frontend);
//   }
//   // always include en-US as a fallback
//   orderedLocales.push('en-US');
//   return orderedLocales;
// }

// This is a workaround for a react-md problem with eastern arabic numerals -
// a regex it is using is looking for ascii digits (aka western arabic numerals)
// so this explicitly sets the numbering system option to latin (-u-nu-latn)
// which is the same as western arabic numerals (0-9).
// export function getOrderedLocalesWithWesternArabicNumerals(localeObject) {
//   const locales = getOrderedLocales(localeObject);
//   return locales.map((l) => (l.startsWith('ar') ? l.concat('-u-nu-latn') : l));
// }

//
// Extract the language and region parts from a locale tag like "en-US".
// Returns an array where the first element is the language ("en") and
// the second is the region ("US").
//
export function getLanguageAndRegion(locale) {
  if (!locale) {
    return [];
  }
  const match = localeRegex.exec(locale);
  if (match && match[1]) {
    const lang = match[1].toLowerCase();
    if (match[2]) {
      const region = match[2].toUpperCase();
      return [lang, region];
    }
    return [lang];
  }
  return [];
}

//
// Return the language that we support for the given locale, or the
// given default if the locale is not supported. This will return one
// of the strings defined in the supportedLanguages array.
//
export function getSupportedLanguage(locale, defaultLanguage, supportedLanguages = SUPPORTED_LANGUAGES) {
  const defaultLang = defaultLanguage === undefined ? 'en' : defaultLanguage;
  const [lang, region] = getLanguageAndRegion(locale);
  if (!lang) {
    return defaultLang;
  }
  if (region) {
    const exact = `${lang}-${region}`;
    if (supportedLanguages.includes(exact)) {
      return exact;
    }
  }
  return supportedLanguages.includes(lang) ? lang : defaultLang;
}

function isLanguageSupported(locale) {
  return getSupportedLanguage(locale, null) !== null;
}

export function getBestLocale(locales) {
  let best = locales.find(isLanguageSupported);
  if (best) {
    // replace underscores with dashes in case the locale came from the back end
    // this should be a no-op for a BCP-47 locale from the browser
    best = best.replace('_', '-');
  }
  return best;
}

//
// Return the directory for Bongo's localization files.
//
export function getBongoLang() {
  const locale = getBestLocale([...getBrowserLocales(), 'en-US']);
  const lang = getSupportedLanguage(locale);
  // we use underscores in the directory names for easier interop with transifex
  return lang.replace('-', '_');
}
