import { isSafari } from 'react-device-detect';
import logger from 'common/utils/logger';

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text).catch((e) => {
      logger.error('[copyTextToClipboard] Could not copy to clipboard.', e);
      throw e;
    });
  } else {
    throw new Error('[copyTextToClipboard] Copy is not supported');
  }
}

export async function copyAsyncResultsToClipboard(method) {
  // Safari does not support access to the clipboard inside of an async callback but we can
  // pass the async method to the ClipboardItem and Safari will handle that fine.
  // Chrome almost supports a similar pattern with the ClipboardItem but it won't take
  // an async method and use that. So, going to just run separate logic for Safari, the general
  // "writeText" pattern works for all the other supported browsers.
  if (isSafari) {
    if ('ClipboardItem' in window && 'clipboard' in navigator) {
      await navigator.clipboard.write([new window.ClipboardItem({ 'text/plain': method })]).catch((e) => {
        logger.error('[copyAsyncResultsToClipboard] Could not copy to clipboard.', e);
        throw e;
      });
    } else {
      logger.error('[copyAsyncResultsToClipboard] Could not copy to clipboard. Make sure you are https in Safari.');
      throw new Error('Could not copy to clipboard in Safari');
    }
  } else {
    const result = await method;
    await copyTextToClipboard(result);
  }
}
