import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import _ from 'lodash';

import Pagination from '@mui/material/Pagination';

import { COURSE } from 'common/dattrs';
import CourseService from 'service/course';
import { getActiveCourseMembers } from 'redux/course/course.selectors';

import AddMemberInput from './AddMemberInput';
import MemberItem from './MemberItem';
import styles from './Members.module.scss';

const PAGE_SIZES = [40];
function Members(props) {
  const { courseId } = props;
  const [page, setPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZES[0]);
  const courseMembers = sortMembers(useSelector(getActiveCourseMembers(courseId)));
  const [memberChunks, setMemberChunks] = useState([]);
  const memberCount = courseMembers ? courseMembers.length : 0;

  function sortMembers(members) {
    return _.orderBy(members, ['givenName', 'familyName', 'userId'], ['asc', 'asc', 'asc']);
  }

  function handlePageChange(event, value) {
    setPage(value);
  }

  const updateMember = useCallback(async (member) => {
    await CourseService.updateCourseMember({ oper: 'update', ...member });
  }, []);

  const deleteMember = useCallback(async (member) => {
    await CourseService.updateCourseMember({ oper: 'delete', ...member });
  }, []);

  useEffect(() => {
    CourseService.loadCourseMembers(courseId);
  }, [courseId]);

  useEffect(() => {
    if (_.isEmpty(courseMembers)) {
      setMemberChunks([]);
      setPage(1);
      return;
    }

    const _memberChunks = _.chunk([...courseMembers], pageSize);
    setMemberChunks(_memberChunks);
    if (page > _memberChunks.length) {
      setPage(_memberChunks.length);
    }
  }, [JSON.stringify(courseMembers)]); //eslint-disable-line

  function getPaginationComponent() {
    if (_.isEmpty(courseMembers) || courseMembers.length <= PAGE_SIZES[0]) {
      return null;
    }

    return (
      <div className={styles.footer}>
        <div className={styles.rightActions}>
          <Pagination
            {...COURSE.members.pagination}
            count={Math.ceil(courseMembers.length / pageSize)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3>{t`Members (${memberCount})`}</h3>
      <AddMemberInput courseId={courseId} />
      <div role='list' {...COURSE.members.list} className={styles.memberList}>
        {_.map(memberChunks[page - 1], (member) => {
          return (
            <MemberItem key={member.userId} member={member} deleteMember={deleteMember} updateMember={updateMember} />
          );
        })}
      </div>

      {getPaginationComponent()}
    </div>
  );
}

Members.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default React.memo(Members);
