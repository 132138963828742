import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './BongoFontAwesomeIcon.module.scss';

const DEFAULT_SIZE = 28;

function BongoFontAwesomeIcon(props) {
  const { className, icon, iconClassName, size, ...rest } = props;
  const wrapperClasses = cx(styles.iconWrapper, className);
  const iconClasses = cx(styles.icon, iconClassName);

  const faProps = _.merge({}, rest, {
    icon,
    style: { size },
  });

  return (
    <div className={wrapperClasses} style={{ '--iconSize': `${size}px` }}>
      <FontAwesomeIcon className={iconClasses} {...faProps} />
    </div>
  );
}

BongoFontAwesomeIcon.defaultProps = {
  size: DEFAULT_SIZE,
};

BongoFontAwesomeIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({ prefix: PropTypes.string, iconName: PropTypes.string, icon: PropTypes.array }).isRequired,
  iconClassName: PropTypes.string,
  size: PropTypes.number,
};

export default BongoFontAwesomeIcon;
