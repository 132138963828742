import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { default as MUIMenuItem } from '@mui/material/MenuItem';

import BongoFontAwesomeIcon from 'components/common/icon/BongoFontAwesomeIcon';
import { dattrsFromProps } from 'common/dattrs';

function MenuItem(props) {
  const { onClick, disabled, icon, label, loading, ...rest } = props;
  return (
    <MUIMenuItem {...dattrsFromProps(props)} sx={{ padding: 1.5 }} disabled={disabled} onClick={onClick} {...rest}>
      {icon && (
        <ListItemIcon>
          {loading ? <CircularProgress size={24} /> : <BongoFontAwesomeIcon icon={icon} size={21} />}
        </ListItemIcon>
      )}
      <ListItemText>{label}</ListItemText>
    </MUIMenuItem>
  );
}

MenuItem.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

MenuItem.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
};

export default MenuItem;
