import React from 'react';
import { Navigate } from 'react-router-dom';
import logger from 'common/utils/logger';

const LOGGER_PREFIX = 'CognitoCallback';

function Callback() {
  // Get the original user's destination from session storage.
  const dest = window.sessionStorage.getItem('bongo-pre-login-destination') || '/';
  logger.debug(`${LOGGER_PREFIX} Navigating to pre-login destination`, { dest });
  return <Navigate to={dest} />;
}

export default Callback;
