import { API, graphqlOperation } from 'aws-amplify';
import store from 'redux/store';
import _ from 'lodash';
import logger from 'common/utils/logger';

import { getActivities, getActivity } from 'graphql/queries';
import {
  createActivity as createActivityMutation,
  updateActivity as updateActivityMutation,
  copyActivity as copyActivityMutation,
  deleteActivity as deleteActivityMutation,
} from 'graphql/mutations';
import {
  addActivity,
  clearActivityFilters,
  setActivities,
  updateActivity,
  deleteActivity,
} from 'redux/activity/activity.actions.js';

const LOGGER_PREFIX = 'ActivityService';
class ActivityService {
  fetchActivities = async (courseId) => {
    try {
      const activityData = await API.graphql(graphqlOperation(getActivities, { courseId }));
      const activities = _.get(activityData, 'data.getActivities.activities');
      const sorted = _.orderBy(activities, ['name'], ['asc']);
      logger.debug(`${LOGGER_PREFIX}::fetchActivities`, sorted);
      store.dispatch(setActivities(sorted));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchActivities`, err);
    }
  };

  fetchActivity = async (id) => {
    try {
      const activityData = await API.graphql(graphqlOperation(getActivity, { id }));
      const activity = _.get(activityData, 'data.getActivity');
      logger.debug(`${LOGGER_PREFIX}::fetchActivity`, activity);
      return activity;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::fetchActivity`, err);
      throw err;
    }
  };

  createActivity = async ({ courseId, resourceLinkId, name, type }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::createActivity config`, { courseId, resourceLinkId, name, type });
      const result = await API.graphql(
        graphqlOperation(createActivityMutation, { input: { courseId, resourceLinkId, name, type } })
      );
      const activity = _.get(result, 'data.createActivity');
      logger.debug(`${LOGGER_PREFIX}::createActivity result`, activity);
      store.dispatch(addActivity(activity));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::createActivity`, err);
    }
  };

  updateActivityStates = async ({ id, states }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::updateActivityVisibility config`, { id, states });
      const result = await API.graphql(graphqlOperation(updateActivityMutation, { input: { id, states } }));
      const activity = _.get(result, 'data.updateActivity');
      logger.debug(`${LOGGER_PREFIX}::updateActivityVisibility result`, activity);
      store.dispatch(updateActivity(activity));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::updateActivityVisibility`, err);
    }
  };

  updateActivity = async ({ id, name }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::updateActivity config`, { id, name });
      const result = await API.graphql(graphqlOperation(updateActivityMutation, { input: { id, name } }));
      const activity = _.get(result, 'data.updateActivity');
      logger.debug(`${LOGGER_PREFIX}::updateActivity result`, activity);
      store.dispatch(updateActivity(activity));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::updateActivity`, err);
    }
  };

  copyActivity = async ({ activeCourseId, courseId, activityId, activityName }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::copyActivity config`, { courseId, activityId, activityName });
      const result = await API.graphql(
        graphqlOperation(copyActivityMutation, {
          input: { targetCourseId: courseId, sourceActivityId: activityId, targetName: activityName },
        })
      );
      const activity = _.get(result, 'data.copyActivity');
      logger.debug(`${LOGGER_PREFIX}::copyActivity result`, activity);

      // not sure what I want to do here long term. We don't want to add activities if it was copied to
      // a different course.
      if (activeCourseId === courseId) {
        store.dispatch(addActivity(activity));
      }
      return activity;
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::copyActivity`, err);
    }
  };

  deleteActivity = async ({ activityId }) => {
    try {
      logger.debug(`${LOGGER_PREFIX}::deleteActivity config`, { id: activityId });
      const result = await API.graphql(graphqlOperation(deleteActivityMutation, { id: activityId }));
      const activity = _.get(result, 'data.deleteActivity');
      logger.debug(`${LOGGER_PREFIX}::deleteActivity result`, activity);
      store.dispatch(deleteActivity(activityId));
    } catch (err) {
      logger.error(`${LOGGER_PREFIX}::deleteActivity`, err);
      throw err;
    }
  };

  clearActivityFilters = () => {
    store.dispatch(clearActivityFilters());
  };
}

export default new ActivityService();
