import loglevel from 'loglevel';
import _ from 'lodash';

const _loglevel = loglevel.noConflict();

const MAIN_MODULE_NAME = 'bongo-direct';
const STICKY_LOG_LEVELS_LS_KEY = 'stickyLogLevels';
let LEVELS = _.keys(_loglevel.levels).map((key) => { return { label: key, value: _loglevel.levels[key] } });
LEVELS = _.sortBy(LEVELS, ['value']);
export { LEVELS };

// Use this logger instead of console
// logger.debug('...', obj) --> console.log('...', obj)

function getDefaultLevel() {
  if (process.env.NODE_ENV !== 'production') {
    return _loglevel.levels.DEBUG;
    // window.onerror = function (message, file, line, col, err) {
    //   logger.error('An error occured at line ' + line + ' of ' + file + ': ' + message, err);
    // };
  } else {
    return _loglevel.levels.WARN;
  }
}

export function getLoggers() {
  return _loglevel.getLoggers();
}

export function getLogger(moduleName, level = getDefaultLevel()) {
  // TODO: Add middleware to loglevel for message formatting so it includes module name and timestamp
  const _logger = _loglevel.getLogger(moduleName);
  let _level = level;
  const stickyLogLevels = getStickyLogLevels();
  if (_.has(stickyLogLevels, moduleName)) {
    _level = stickyLogLevels[moduleName];
  }
  _logger.setLevel(_level);
  return _logger;
}

export function getStickyLogLevels() {
  const { localStorage } = window;
  // localStorage is not defined when running unit tests. Check to prevent false test failures
  if (localStorage){
    try {
      const stickyLogLevels = JSON.parse(localStorage.getItem(STICKY_LOG_LEVELS_LS_KEY));
      return stickyLogLevels || {};
    } catch (e) {
      logger.debug('Could not read "' + STICKY_LOG_LEVELS_LS_KEY + '" from localStorage.', e);
    }
  }
  return {};
}

export function updateStickyLogLevels(stickyLogLevels) {
  const { localStorage } = window;
  localStorage.setItem(STICKY_LOG_LEVELS_LS_KEY, JSON.stringify(stickyLogLevels));
}

const logger = getLogger(MAIN_MODULE_NAME);
export default logger;
