import _ from 'lodash';

import {
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  SET_ACTIVITIES,
  SET_ACTIVITY_SORT_FIELD,
  SET_ACTIVITY_SORT_DIRECTION,
  SET_ACTIVITY_FILTER_FIELD_VALUE,
  UPDATE_ACTIVITY_FILTERS,
  CLEAR_ACTIVITY_FILTERS,
  DELETE_ACTIVITY,
} from './activity.types';
import { ACTIVITY_DEFAULT_SORT_FIELD, ACTIVITY_DEFAULT_SORT_DIRECTION } from './activity.constants';
import { VISIBLE, HIDDEN } from 'common/constants/general';

const INITIAL_STATE = {
  rawActivities: [],
  activities: [],
  filterValue: '',
  filters: {
    type: [],
    visibility: [],
  },
  sortColumn: ACTIVITY_DEFAULT_SORT_FIELD,
  sortDirection: ACTIVITY_DEFAULT_SORT_DIRECTION,
};

const getSortedFilteredActivityList = (state) => {
  const { rawActivities, filterValue, filters } = state;
  let _rawActivities = [...rawActivities];
  let _filterValue = _.trim(filterValue);

  if (!_.isEmpty(_.trim(_filterValue))) {
    _rawActivities = _.filter(_rawActivities, (activity) => {
      const { name } = activity;
      const matchesName = Boolean(name.toLowerCase().indexOf(_filterValue.toLowerCase()) >= 0);
      return matchesName;
    });
  }

  if (!_.isEmpty(filters.type)) {
    _rawActivities = _.filter(_rawActivities, (activity) => {
      const { type } = activity;
      return filters.type.includes(type);
    });
  }

  if (!_.isEmpty(filters.visibility)) {
    _rawActivities = _.filter(_rawActivities, (activity) => {
      const {
        states: { visible },
      } = activity;

      if (visible && filters.visibility.includes(VISIBLE)) {
        return true;
      } else if (!visible && filters.visibility.includes(HIDDEN)) {
        return true;
      }

      return false;
    });
  }

  return _.orderBy(_rawActivities, [state.sortColumn], [state.sortDirection]);
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVITIES: {
      const tempState = { ...state, rawActivities: [...action.payload] };
      return {
        ...tempState,
        activities: getSortedFilteredActivityList(tempState),
      };
    }
    case ADD_ACTIVITY: {
      const rawActivities = [...state.rawActivities, action.payload];
      const tempState = {
        ...state,
        rawActivities,
      };
      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case UPDATE_ACTIVITY: {
      const activity = action.payload;
      const rawActivities = [...state.rawActivities];
      const activityIndex = _.findIndex(rawActivities, { id: activity.id });
      rawActivities[activityIndex] = activity;

      const tempState = {
        ...state,
        rawActivities,
      };

      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case DELETE_ACTIVITY: {
      const { id } = action;
      const _rawActivities = [...state.rawActivities];
      const _activities = [...state.activities];

      _.remove(_rawActivities, (activity) => {
        return activity.id === id;
      });

      _.remove(_activities, (activity) => {
        return activity.id === id;
      });

      return { ...state, rawActivities: _rawActivities, activities: _activities };
    }
    case SET_ACTIVITY_SORT_FIELD: {
      const sortColumn = action.value;
      const tempState = {
        ...state,
        sortColumn,
      };

      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case SET_ACTIVITY_SORT_DIRECTION: {
      const sortDirection = action.value;
      const tempState = {
        ...state,
        sortDirection,
      };
      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case SET_ACTIVITY_FILTER_FIELD_VALUE: {
      const filterValue = action.value;
      const tempState = {
        ...state,
        filterValue,
      };
      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case UPDATE_ACTIVITY_FILTERS: {
      const tempState = {
        ...state,
        filters: action.filters,
      };
      return { ...tempState, activities: getSortedFilteredActivityList(tempState) };
    }
    case CLEAR_ACTIVITY_FILTERS:
      return {
        ...state,
        filterValue: '',
        filters: {
          type: [],
          visibility: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
